import {
  Breadcrumbs,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { MyContext } from "../../App";
import { FaCloudUploadAlt } from "react-icons/fa";
import { editData, fetchDataFromApi, postData } from "../../utils/api";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const EditSubCat = () => {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/"}
      onClick={handleClick}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={handleClick}
    >
      Sub Category
    </Link>,
    <Typography key="3" color="text.primary">
      Edit Sub Category
    </Typography>,
  ];
  const context = useContext(MyContext);
  const [subCat, setSubCat] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  
  const navigate = useNavigate();
  const formData = new FormData();
  const [formFields, setFormFields] = useState({
    category: "",
    subCat: "",
  });

  const changeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };
  const { id } = useParams()

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    setFormFields(() => ({
      ...formFields,
      category: event.target.value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0,0)
    context.setProgress(20);
    fetchDataFromApi(`/api/subCat/${id}`).then((res) => {
      setSubCat(res);
      setCategory(res.category.id)
      setFormFields({
        category: res.category,
        subCat: res.subCat,
      })
      context.setProgress(100);
    })
    context.setProgress(20);
    const shop = JSON.parse(localStorage.getItem("shop"));
    fetchDataFromApi(`/api/category?shop=${shop._id}`).then((res) => {
      setCategoryData(res)
      context.setProgress(100)
    })
  }, [])

  const editSubCat = (e) => {
    e.preventDefault();
    formData.append("category", formFields.category);
    formData.append("subCat", formFields.subCat);
    if (formFields.category === "") {
      context.setAlertBox({
        open: true,
        color: "error",
        msg: "Category is required",
      });
      return false;
    }
    if (formFields.subCat === "") {
      context.setAlertBox({
        open: true,
        color: "error",
        msg: "Sub Category is required",
      });
      return false;
    }
    setIsLoading(true);
    editData(`/api/subCat/${id}`, formFields).then((res) => {
      setIsLoading(false)
      navigate('/subCategory');
    })
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 flex-row p-4">
          <h5>Edit Sub Category</h5>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className="ml-auto"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <form className="form" onSubmit={editSubCat}>
          <div className="row">
            <div className="col-sm-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>CATEGORY</h6>
                      <Select
                        value={category}
                        onChange={handleChangeCategory}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {categoryData?.categoryList?.length !== 0 &&
                          categoryData?.categoryList?.map(
                            (cat, index) => (
                              <MenuItem value={cat.id} key={index}>
                                {cat.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>Sub Category</h6>
                      <input type="text" name="subCat" value={formFields.subCat} onChange={changeInput} />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  className="btn-blue btn-big btn-lg uploadBtn w-100"
                >
                  <FaCloudUploadAlt /> &nbsp;
                  {isLoading === true ? (
                    <CircularProgress className="loader" color="inherit" />
                  ) : (
                    "PUBLISH AND VIEW"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditSubCat;
