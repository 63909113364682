import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import { MdEmail } from "react-icons/md";
import { IoIosLock, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { Button, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { postData } from "../../utils/api.js";
import axios from "axios";

const Login = () => {
  const [isFocus, setIsFocus] = useState(null);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const onFocus = (index) => {
    setIsFocus(index);
  };
  const history = useNavigate();
  const context = useContext(MyContext);
  const onClickSignUp = () => {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "You need to contact with admin",
    });
  }
  const [formfields, setFormfields] = useState({
    email: "",
    password: "",
    isAdmin: true
  });
  useEffect(() => {
    context.setIsHideSidebarAndHeader(true);
    window.scrollTo(0, 0);
    // const token = localStorage.getItem("token");
    // if (token !== "" && token !== undefined && token !== null) {
    //   setIsLogin(true);
    //   history("/dashboard");
    // } else {
    //   history("/login");
    // }
  }, []);

  const onchangeInput = (e) => {
    setFormfields(() => ({
      ...formfields,
      [e.target.name]: e.target.value,
    }));
  };

  const signIn = async (e) => {
    e.preventDefault();

    if (formfields.email === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "email can not be blank!",
      });
      return false;
    }

    if (formfields.password === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "password can not be blank!",
      });
      return false;
    }

    
    try {
      setIsLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/signin`, formfields)
      if(res?.error){
        if(res.error == 'CredentialSignin'){
          context.setAlertBox({
            open: true,
            color: "error",
            msg:"Incorrect email or password"
          })
          setIsLoading(false)
        }else{
          context.setAlertBox({
            open: true,
            color: "error",
            msg:res.error
          })
          setIsLoading(false)
        }
      }
      setIsLoading(true);
      console.log(res.data.user)
      localStorage.setItem("token", res?.data?.token);
      const user = {
        name: res.data?.user?.name,
        email: res.data?.user?.email,
        userId: res.data?.user?.id,
      }
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem('shop', JSON.stringify(res?.data?.shop));
      setIsLoading(true);
          context.setAlertBox({
            open: true,
            color: "success",
            msg:"User lognin successfully!"
          })
      setIsLoading(false)
      window.location.href = "/dashboard";
    } catch (error) {
      const axiosError =  error;
      let errMessage = axiosError.response?.data.msg;
      context.setAlertBox({
        open: true,
        color: "error",
        msg:errMessage
      })
      setIsLoading(false)
    }
    
  };

  return (
    <>
      <section className="loginSection">
        <div className="overlay"></div>

        <div className="logo text-center">
          <h1>Raheeq</h1>
          <h5>Login to RG Store</h5>
        </div>
        <div className="loginBox">
          <div className="wrapper mt-3 card border">
            <form onSubmit={signIn}>
              <div
                className={`form-group mb-3 position-relative ${
                  isFocus === 0 && "focus"
                }`}
              >
                <span className="icon">
                  <MdEmail />
                </span>
                <input
                  onFocus={() => onFocus(0)}
                  onBlur={() => setIsFocus(null)}
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  autoFocus
                  name="email"
                  onChange={onchangeInput}
                />
              </div>
              <div
                className={`form-group position-relative ${
                  isFocus === 1 && "focus"
                }`}
              >
                <span className="icon">
                  <IoIosLock />
                </span>
                <input
                  onFocus={() => onFocus(1)}
                  onBlur={() => setIsFocus(null)}
                  type={`${isShowPassword === true ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Enter your password"
                  name="password"
                  onChange={onchangeInput}
                />
                <span
                  className="toggleShowPassword"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                >
                  {isShowPassword === true ? <IoMdEyeOff /> : <IoMdEye />}
                </span>
              </div>
              <div className="form-group">
                <Button type="submit" className="btn-blue btn-lg w-100 btn-big loadingIcon">
                  {isLoading === true ? <CircularProgress /> : "Sign In "}
                </Button>
              </div>
              <div className="form-group text-center mb-0">
                <Link to={"#"} className="link">
                  FORGOT PASSWORD
                </Link>
                <div className="flex1 or mt-3 mb-3">
                  <span className="line"></span>
                  <span className="txt">or</span>
                  <span className="line"></span>
                </div>
                <Button
                  variant="outlined"
                  className="w-100 btn-big loginWithGoogle"
                >
                  <FcGoogle /> Sign In with Google
                </Button>
              </div>
            </form>
          </div>
          <div className="wrapper mt-1 card border footer p-4">
            <span className="text-center">
              Don't have an account?{" "}
              <Link to={"#"} onClick={onClickSignUp} className="link ml-2">
                Register
              </Link>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
