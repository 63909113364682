import { Button } from "@mui/material";
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RestoreIcon from '@mui/icons-material/Restore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoTimerOutline } from "react-icons/io5";

const ITEM_HEIGHT = 48;

const DashboardBox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Button
      className="dashboardBox"
      style={{
        backgroundImage: `linear-gradient(to right, ${props.color?.[0]} , ${props.color?.[1]})`,
      }}
    >
      {
        props.grow === true ?
        <span className="chart"><TrendingUpIcon/></span> :
        <span className="chart"><TrendingDownIcon/></span>
      }
      <div className="d-flex w-100 bottomEle">
        <div className="col1">
          <h6 className="text-white">{props?.title}</h6>
          <span className="text-white">{props?.count}</span>
        </div>
        <div className="ml-auto">
          <span className="icon">
            {props.icon}
          </span>
        </div>
      </div>
      <div className="flex2 w-100">
        <h6 className="text-white mb-0 mt-0">Last month</h6>
        <div className="ml-auto">
        <Button className="toggleIcon" onClick={handleClick}><BsThreeDotsVertical/></Button>
        <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}

      >
          <MenuItem onClick={handleClose}>
             <RestoreIcon/> Last day
          </MenuItem>
          <MenuItem onClick={handleClose}>
          <RestoreIcon/> Last week
          </MenuItem>
          <MenuItem onClick={handleClose}>
          <RestoreIcon/> Last Month
          </MenuItem>
          <MenuItem onClick={handleClose}>
          <RestoreIcon/> Last Year
          </MenuItem>
      </Menu>
        </div>
      </div>
    </Button>
  );
};

export default DashboardBox;
