import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FaEdit } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import MenuItem from "@mui/material/MenuItem";
import { Button, FormControl, Pagination } from "@mui/material";
import Select from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import { deleteData, editData, fetchDataFromApi } from "../../utils/api";
import { HiOutlinePlus } from "react-icons/hi2";
import { MyContext } from "../../App";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const SubCategory = () => {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/"}
      onClick={handleClick}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={handleClick}
    >
      Sub Category
    </Link>,
    <Typography key="3" color="text.primary">
      Sub Category List
    </Typography>,
  ];
  const [showBy, setShowBy] = useState("");
  const [showCatBy, setShowCatBy] = useState("");
  const [brandBy, setBrandBy] = useState("");
  const context = useContext(MyContext)
  const [subCatData, setSubCatData] = useState([]);
  


  useEffect(() => {
    window.scrollTo(0,0)
    context.setProgress(20)
    const shop = JSON.parse(localStorage.getItem("shop"))
    fetchDataFromApi(`/api/subCat?shop=${shop._id}`).then((res) => {
      setSubCatData(res)
      context.setProgress(100)
    })
  }, [])

  const deleteCategory = (id) => {
    deleteData(`/api/subCat/${id}`).then((res) =>{
      fetchDataFromApi('/api/subCat').then((res) => {
        setSubCatData(res)
      });
    })
  }

  const handleChange = (e, value) => {
    context.setProgress(20)
    fetchDataFromApi(`/api/category?page=${value}`).then((res) => {
      setSubCatData(res)

      context.setProgress(100);
    });
  }

  return (
    <>
      <div className="right-content w-100">
      <div className="card shadow border-0 flex-row p-4">
      <Link to={'/subCategory/add'}><Button className="btn-blue btn-lg addCatBtn mr-3"><HiOutlinePlus/> &nbsp; Add New Sub Category</Button></Link>
        <div className="ml-auto flex2">
          
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="ml-auto"
        >
          {breadcrumbs}
        </Breadcrumbs>
        </div>
      </div>
      <div className="card shadow border-0 p-3 mt-4">
        <h3 className="hd">Sub Category List</h3>
        <div className="row cardFilters  mt-3">
          <div className="col-md-3">
            <h4>SHOW BY</h4>
            <FormControl className="w-100" size="small">
              <Select
                displayEmpty
                inputProps={{ "aria-label": "without label" }}
                value={showBy}
                onChange={(e) => setShowBy(e.target.value)}
                className="w-100"
              >
                <MenuItem value={""}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-3">
            <h4>CATEGORY BY</h4>
            <FormControl className="w-100" size="small">
              <Select
                displayEmpty
                inputProps={{ "aria-label": "without label" }}
                value={showCatBy}
                onChange={(e) => setShowCatBy(e.target.value)}
                className="w-100"
              >
                <MenuItem value={""}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-3">
            <h4>BRAND BY</h4>
            <FormControl className="w-100" size="small">
              <Select
                displayEmpty
                inputProps={{ "aria-label": "without label" }}
                value={brandBy}
                onChange={(e) => setBrandBy(e.target.value)}
                className="w-100"
              >
                <MenuItem value={""}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="table-responsive mt-3">
          <table className="table table-bordered v-align">
            <thead className="thead-dark">
              <tr>
                <th>UID</th>
                <th>CATEGORY IMAGE</th>
                <th>CATEGORY</th>
                <th>SUB CATEGORY</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {
                subCatData?.subCatList?.length === 0 ? <p>No category to show</p> : (
                  subCatData?.subCatList?.map((item, index) => (
                    <tr key={index}>
                <td>#{index+1}</td>
                <td>
                  <div className="flex2 productBox">
                    <div className="imgWrapper">
                      <div className="img">
                        <img
                          src={`${item.category.images[0]}`}
                          alt=""
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>{item.category.name}</td>
                <td>{item.subCat}</td>
                
                
                <td>
                  <div className="actions flex2">
                   <Link to={`/subCategory/edit/${item.id}`}>
                   <Button className="success" color="success">
                      <FaEdit />
                    </Button>
                   </Link>
                    <Button className="error" color="error" onClick={() => deleteCategory(item.id)}>
                      <IoMdTrash />
                    </Button>
                  </div>
                </td>
              </tr>
                  ))
                )
              }
              
            </tbody>
          </table>
          {
              subCatData?.totalPages>1 && <div className="d-flex tableFooter">
              <p>
                Showing <b>1</b> of <b>10</b> results
              </p>
              <Pagination
                count={subCatData?.totalPages}
                className="pagination"
                showFirstButton
                showLastButton
                color="primary"
                onChange={handleChange}
              />
            </div>
            }
        </div>
      </div>
    </div>
    
    </>
  );
};

export default SubCategory;
