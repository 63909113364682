import { useEffect, useState } from "react";
import logo from './assests/hibuylogo.png'

const ScreenSizeCheck = ({ children, setSidebarVisible }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1100);

  useEffect(() => {
    const handleResize = () => {
      const isLarge = window.innerWidth > 1100;
      setIsLargeScreen(isLarge);
      setSidebarVisible(isLarge); // Update sidebar visibility
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setSidebarVisible]);

  return isLargeScreen ? children : <div className="message">
    <div className="messageWrapper">
        <div className="logo">
            <div className="logo-wrapper">
                <img src={logo} className="text-center" alt="logo" />
            </div>
        </div>
        <p>You can only access the dashboard on a PC or computer</p>
    </div>
  </div>;
};

export default ScreenSizeCheck;
