import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { MdDashboard } from "react-icons/md";
import { FaAngleDown, FaAngleRight, FaBell } from "react-icons/fa6";
import { FaPix } from "react-icons/fa6";
import { IoMdCart, IoMdLogOut } from "react-icons/io";
import { MdMessage } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from "../../App";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { FaProductHunt } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";

const Sidebar = () => {
  const context = useContext(MyContext);
  const [activeTab, setActiveTab] = useState(0);
  const [isToggleSubmenu, setIsToggleSubmenu] = useState(false);
  const isOpenSubmenu = (index) => {
    setActiveTab(index);
    setIsToggleSubmenu(!isToggleSubmenu);
  };
  return (
    <>
      <div className="sidebar">
        <ul>
          <li>
            <Link to={"/"}>
              <Button
                className={`w-100 ${activeTab === 0 ? "active" : ""}`}
                onClick={() => isOpenSubmenu(0)}
              >
                <span className="icon">
                  <MdDashboard />
                </span>
                Dashboard
                {/* <span className="arrow">
                  <FaAngleRight />
                </span> */}
              </Button>
            </Link>
          </li>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 1 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(1)}
            >
              <span className="icon">
                <BiSolidCategoryAlt />
              </span>
              Category
              <span className="arrow">
                <FaAngleRight />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 1 && isToggleSubmenu === true
                  ? "colapse"
                  : "colapsed"
              }`}
            >
              <ul className="submenu">
                {/* <li>
                  <Link to={"/category"}>Category List</Link>
                </li>
                <li>
                  <Link to={"/category/add"}>Add a Category</Link>
                </li> */}
                <li>
                  <Link to={"/subCategory"}>Sub Category List</Link>
                </li>
                <li>
                  <Link to={"/subCategory/add"}>Add a Sub Category</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Button
              className={`w-100 ${
                activeTab === 2 && isToggleSubmenu === true ? "active" : ""
              }`}
              onClick={() => isOpenSubmenu(2)}
            >
              <span className="icon">
                <FaPix />
              </span>
              Products
              <span className="arrow">
                <FaAngleRight />
              </span>
            </Button>
            <div
              className={`submenuWrapper ${
                activeTab === 2 && isToggleSubmenu === true
                  ? "colapse"
                  : "colapsed"
              }`}
            >
              <ul className="submenu">
                <li>
                  <Link to={"/products"}>Product List</Link>
                </li>
                <li>
                  <Link to={"/product/upload"}>Product Upload</Link>
                </li>
                <li>
                  <Link exact activeClassName="is-active" to="/productCOLOR/add">
                    Add Product Color
                  </Link>
                </li> 
                <li>
                  <Link exact activeClassName="is-active" to="/additionalDetails/add">
                    Additional Details
                  </Link>
                </li> 
                <li>
                  <Link exact activeClassName="is-active" to="/productRAMS/add">
                    Add Product RAMS
                  </Link>
                </li>
                <li>
                  <Link
                    exact
                    activeClassName="is-active"
                    to="/productWEIGHT/add"
                  >
                    Add Product WEIGHT
                  </Link>
                </li>
                <li>
                  <Link exact activeClassName="is-active" to="/productSIZE/add">
                    Add Product SIZE
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
                        <Link exact activeClassName='is-active' to="/orders">
                            <Button className={`w-100 ${activeTab === 3 && isToggleSubmenu === true ? 'active' : ''}`} onClick={() => isOpenSubmenu(3)}>
                                <span className='icon'> <FaClipboardCheck fontSize="small" /></span>
                                Orders
                            </Button>
                        </Link>
                    </li>
                    {/* <li>
                        <Button className={`w-100 ${activeTab === 4 && isToggleSubmenu === true ? 'active' : ''}`} onClick={() => isOpenSubmenu(4)}>
                            <span className='icon'><FaProductHunt /></span>
                            Home Banner
                            <span className='arrow'><FaAngleRight /></span>
                        </Button>
                        <div className={`submenuWrapper ${activeTab === 4 && isToggleSubmenu === true ? 'colapse' : 'colapsed'}`}>
                            <ul className='submenu'>
                                <li><Link exact activeClassName='is-active' to="/homeBannerSlide/add">Add Home Banner Slide</Link></li>
                                <li><Link exact activeClassName='is-active' to="/homeBannerSlide/list">Home Slides List</Link></li>
                            </ul>
                        </div>
                    </li> */}
          {/* <li>
            <Link to={'/'}>
            <Button  className={`w-100 ${activeTab === 2 ? 'active' : ''}`}  onClick={() => isOpenSubmenu(2)}>
              <span className="icon"><IoMdCart/></span>
                Orders
              <span className="arrow"><FaAngleRight/></span>
            </Button>
            </Link>
          </li>
          <li>
            <Link to={'/'}>
            <Button className={`w-100 ${activeTab === 3 ? 'active' : ''}`}  onClick={() => isOpenSubmenu(3)}>
              <span className="icon"><MdMessage/></span>
                Messages
            </Button>
            </Link>
          </li>
          <li>
            <Link to={'/'}>
            <Button className={`w-100 ${activeTab === 4 ? 'active' : ''}`}  onClick={() => isOpenSubmenu(4)}>
              <span className="icon"><FaBell/></span>
                Notifications
            </Button>
            </Link>
          </li>
          <li>
            <Link to={'/'}>
            <Button className={`w-100 ${activeTab === 5 ? 'active' : ''}`}  onClick={() => isOpenSubmenu(5)}>
              <span className="icon"><IoSettings/></span>
                Settings
            </Button>
            </Link>
          </li> */}
        </ul>
        <br />
        <div className="logoutWrapper">
          <div className="logoutBox">
            <Button variant="contained" onClick={() => localStorage.clear()}>
              <IoMdLogOut />
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
