import React, { useEffect, useState } from "react";
import { editData, fetchDataFromApi } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import axios from "axios";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const OrderDetails = () => {
  const [orderData, setOrderData] = useState([]);
  const [singleOrder, setSingleOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProducts = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${id}`);
        setOrderData(res?.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response) {
            if (err.response.status === 404) {
              navigate('/page-not-found');
            } else if (err.response.status === 400) {
              navigate('/page-not-found');
            } else {
              setError('An unexpected error occurred');
            }
          } else {
            setError('An unexpected error occurred');
          }
        } else {
          setError('An unexpected error occurred');
        }
      }
    };
    fetchProducts();
  }, [id, navigate]);

  const orderStatusHandler = (currentStatus, id) => {
    const statusSequence = ["Pending", "Confirm", "Shipped", "Delivered"];
    const currentIndex = statusSequence.indexOf(currentStatus);
    const nextStatus = statusSequence[currentIndex + 1];

    if (currentStatus !== "Delivered" && nextStatus) {
      fetchDataFromApi(`/api/orders/${id}`).then((res) => {
        const order = {
          ...res,
          status: nextStatus,
        };

        editData(`/api/orders/${id}`, order).then((res) => {
          fetchDataFromApi(`/api/orders/${id}`).then((updatedRes) => {
            setOrderData(updatedRes);
            window.scrollTo({
              top: 200,
              behavior: "smooth",
            });
          });
          setSingleOrder(res?.products);
        });
      });
    } else {
      console.log("Order is already delivered, no further updates allowed.");
    }
  };

  const isDisabled = orderData?.status === "Delivered" || orderData?.status === "Cancelled";

  return (
    <div className="right-content w-100">
      <div className="card shadow border-0 w-100 flex-row p-4 align-items-center">
        <h5 className="mb-0">Orders Details</h5>
        <div className="ml-auto d-flex align-items-center">
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="Dashboard"
              icon={<HomeIcon fontSize="small" />}
            />
            <StyledBreadcrumb label="Orders" deleteIcon={<ExpandMoreIcon />} />
            <StyledBreadcrumb
              label="Order Details"
              deleteIcon={<ExpandMoreIcon />}
            />
          </Breadcrumbs>
        </div>
      </div>
      <div className="od-Container">
        <div className="od-card p-3 mt-1">
          <div className="od-card-container">
            <h1>ORDER INFO</h1>
            <div>
              <h6>Order Id :</h6>
              <p>{orderData?.id}</p>
            </div>
            <div className="details">
              <h5>User Info</h5>
              <p>Name: {orderData?.name}</p>
              <p>Address: {orderData?.address}</p>
              <p>Zip: {orderData?.pincode}</p>
            </div>
            <div className="details">
              <h5>Amount Info</h5>
              <p>Subtotal: {orderData?.amount}</p>
              <p>Shipping Charges: 0</p>
              <p>Tax: 0</p>
              <p>Discount: 0</p>
              <p>Total: {orderData?.amount}</p>
            </div>
            <div className="details">
              <h5>Status Info</h5>
              <p>
                Status:{" "}
                <span
                  className={
                    orderData?.status === "Pending" || orderData?.status === "Cancelled" 
                      ? "badge badge-danger"
                      : "badge badge-success"
                  }
                >
                  {orderData?.status}
                </span>
              </p>
            </div>
            <Button
              onClick={() => orderStatusHandler(orderData?.status, id)}
              disabled={isDisabled}
              className="btn btn-blue w-100 mt-2"
            >
              {isDisabled ? "Status" : "Update Status"}
            </Button>
          </div>
        </div>
        <div className="od-card p-3 mt-2">
          <div className="od-card-container">
            <h1>PRODUCT INFO</h1>
            {orderData?.products?.length !== 0 &&
              orderData?.products?.map((item, i) => {
                const shop = JSON.parse(localStorage.getItem("shop"));
                return (
                  <>
                    {item?.shop === shop._id && (
                      <div key={i}>
                        <div className="details mb-3">
                          <h5>Product Details</h5>
                          <p>Product Id: {item?.id}</p>
                          <p>
                            Image:{" "}
                            <img src={item?.image} alt="" className="ml-5" />
                          </p>
                          <p>Name: {item?.productTitle}</p>
                          <p>Price: {item?.price}</p>
                          <p>Quantity: {item?.quantity}</p>
                          <p>SubTotal: {item?.subTotal}</p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
