import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyContext } from '../../App';
import Button from '@mui/material/Button';
import { FaCloudUploadAlt } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import { deleteData, editData, fetchDataFromApi, postData } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

// Breadcrumb code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const AdditionalDetails = () => {
    const [editId, setEditId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [additionalDetails, setAdditionalDetails] = useState([]);
    const shop = JSON.parse(localStorage.getItem("shop"));
    const [formFields, setFormFields] = useState({
        detail: '',
        shop: shop ? shop._id : null
    });

    const context = useContext(MyContext);

    const inputChange = (e) => {
        setFormFields(prevFields => ({
            ...prevFields,
            [e.target.name]: e.target.value
        }));
    };

    useEffect(() => {
        if (shop && shop._id) {
            fetchDataFromApi(`/api/additionalDetails?shop=${shop._id}`).then((res) => {
                setAdditionalDetails(res);
            });
        }
    }, [shop]);

    const addAdditionalDetails = (e) => {
        e.preventDefault();

        if (!formFields.detail) {
            context.setAlertBox({
                open: true,
                error: true,
                msg: 'Please add Additional Details'
            });
            return;
        }

        setIsLoading(true);

        if (!editId) {
            postData('/api/additionalDetails/create', formFields).then(res => {
                setIsLoading(false);
                setFormFields({
                    detail: '',
                    shop: shop ? shop._id : null
                });

                fetchDataFromApi(`/api/additionalDetails?shop=${shop._id}`).then((res) => {
                    setAdditionalDetails(res);
                });
            });
        } else {
            editData(`/api/additionalDetails/${editId}`, formFields).then((res) => {
                fetchDataFromApi(`/api/additionalDetails?shop=${shop._id}`).then((res) => {
                    setAdditionalDetails(res);
                    setEditId('');
                    setIsLoading(false);
                    setFormFields({
                        detail: '',
                        shop: shop ? shop._id : null
                    });
                });
            });
        }
    };

    const deleteItem = (id) => {
        deleteData(`/api/additionalDetails/${id}`).then((res) => {
            fetchDataFromApi(`/api/additionalDetails?shop=${shop._id}`).then((res) => {
                setAdditionalDetails(res);
            });
        });
    };

    const updateData = (id) => {
        fetchDataFromApi(`/api/additionalDetails/${id}`).then((res) => {
            setEditId(id);
            setFormFields({
                detail: res.detail,
                shop: shop ? shop._id : null
            });
        });
    };

    return (
        <div className="right-content w-100">
            <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
                <h5 className="mb-0">Add Additional Details</h5>
                <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
                    <StyledBreadcrumb
                        component="a"
                        href="#"
                        label="Dashboard"
                        icon={<HomeIcon fontSize="small" />}
                    />
                    <StyledBreadcrumb
                        component="a"
                        label="Additional Details"
                        href="#"
                        deleteIcon={<ExpandMoreIcon />}
                    />
                    <StyledBreadcrumb
                        label="Add Additional Details"
                        deleteIcon={<ExpandMoreIcon />}
                    />
                </Breadcrumbs>
            </div>

            <form className='form' onSubmit={addAdditionalDetails}>
                <div className='row'>
                    <div className='col-sm-9'>
                        <div className='card p-4 mt-0'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <h6>Additional Details</h6>
                                        <input type='text' name="detail" value={formFields.detail} onChange={inputChange} />
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" className="btn-blue btn-lg btn-big w-100">
                                <FaCloudUploadAlt /> &nbsp;  {isLoading ? <CircularProgress color="inherit" className="loader" /> : 'PUBLISH AND VIEW'}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>

            {additionalDetails.length !== 0 && (
                <div className='row'>
                    <div className='col-md-9'>
                        <div className='card p-4 mt-0'>
                            <div className="table-responsive mt-3">
                                <table className="table table-bordered table-striped v-align">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Additional Details</th>
                                            <th width="25%">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {additionalDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.detail}</td>
                                                <td>
                                                    <div className="actions d-flex align-items-center">
                                                        <Button className="success" color="success" onClick={() => updateData(item._id)}>
                                                            <FaPencilAlt />
                                                        </Button>
                                                        <Button className="error" color="error" onClick={() => deleteItem(item._id)}>
                                                            <MdDelete />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdditionalDetails;
