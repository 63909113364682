import React from 'react'

const PageNotFound = () => {
  return (
    <div className="right-content w-100">
        <div className="pageNotFoundContainer">
            <h1>Uh-Oh...</h1>
            <p>Page you were looking for does not exists.</p>
        </div>
    </div>
  )
}

export default PageNotFound