import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import { MdContactPhone, MdEmail } from "react-icons/md";
import { IoIosLock, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { Button, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FaHome, FaUserCircle } from "react-icons/fa";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import axios from "axios";

const SignUp = () => {
  const context = useContext(MyContext);
  const [isFocus, setIsFocus] = useState(null);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formfields, setFormfields] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    isAdmin: true
})

const onchangeInput = (e) => {
  setFormfields(() => ({
      ...formfields,
      [e.target.name]: e.target.value
  }))
}

const onFocus = (index) => {
  setIsFocus(index);
};
useEffect(() => {
  context.setIsHideSidebarAndHeader(true);
  window.scrollTo(0,0)
}, []);

const history = useNavigate()


const signUp = async (e) => {

  e.preventDefault();
  if (formfields.name === "") {
    context.setAlertBox({
        open: true,
        color: "error",
        msg: "name can not be blank!"
    })
    return false;
}

if (formfields.email === "") {
    context.setAlertBox({
        open: true,
        color: "error",
        msg: "email can not be blank!"
    })
    return false;
}

if (formfields.phone === "") {
    context.setAlertBox({
        open: true,
        color: "error",
        msg: "phone can not be blank!"
    })
    return false;
}

if (formfields.password === "") {
    context.setAlertBox({
        open: true,
        color: "error",
        msg: "password can not be blank!"
    })
    return false;
}

if (formfields.confirmPassword === "") {
    context.setAlertBox({
        open: true,
        color: "error",
        msg: "confirm password can not be blank!"
    })
    return false;
}

if (formfields.confirmPassword !== formfields.password) {
    context.setAlertBox({
        open: true,
        color: "error",
        msg: "password not match"
    })
    return false;
}
  try {

      setIsLoading(true);

      const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/signup`, formfields)
      console.log(result)
      
          if(result?.response?.data?.error === false){
            context.setAlertBox({
              open: true,
              color: "error",
              msg: result.response.data.msg
          });
          }else{
            
            context.setAlertBox({
              open: true,
              color: "success",
              msg: "Register Successfully!"
          });
          // Store token and user data
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('user', JSON.stringify(result.data.user));
          localStorage.setItem('shop', JSON.stringify(result.data.shop));
          history('/login')
          }
          

  } catch (error) {
    setIsLoading(false)
    const axiosError =  error;
      let errMessage = axiosError.response?.data.msg;
      context.setAlertBox({
        open: true,
        color: "error",
        msg:errMessage
      })
  }


}

// const signUp = (e) => {
//   e.preventDefault();
//   try {

//       if (formfields.name === "") {
//           context.setAlertBox({
//               open: true,
//               error: true,
//               msg: "name can not be blank!"
//           })
//           return false;
//       }

//       if (formfields.email === "") {
//           context.setAlertBox({
//               open: true,
//               error: true,
//               msg: "email can not be blank!"
//           })
//           return false;
//       }

//       if (formfields.phone === "") {
//           context.setAlertBox({
//               open: true,
//               error: true,
//               msg: "phone can not be blank!"
//           })
//           return false;
//       }

//       if (formfields.password === "") {
//           context.setAlertBox({
//               open: true,
//               error: true,
//               msg: "password can not be blank!"
//           })
//           return false;
//       }

//       if (formfields.confirmPassword === "") {
//           context.setAlertBox({
//               open: true,
//               error: true,
//               msg: "confirm password can not be blank!"
//           })
//           return false;
//       }

//       if (formfields.confirmPassword !== formfields.password) {
//           context.setAlertBox({
//               open: true,
//               error: true,
//               msg: "password not match"
//           })
//           return false;
//       }

//       setIsLoading(true);

//       postData("/api/user/signup", formfields).then((res) => {
//           console.log(res)

//           if (res.error !== false) {
//               context.setAlertBox({
//                   open: true,
//                   error: false,
//                   msg: "Register Successfully!"
//               });
             
//               setTimeout(() => {
//                   setIsLoading(true);
//                   history("/login");
//               }, 2000);
//           }

//           else {
//               setIsLoading(false);
//               context.setAlertBox({
//                   open: true,
//                   error: true,
//                   msg: res.msg
//               });
//           }

//       }).catch(error => {
//           setIsLoading(false);
//           console.error('Error posting data:', error);
//           // Handle error (e.g., show an error message)
//       });

//   } catch (error) {
//       console.log(error)
//   }


// }

  return (
    <>
      <section className="loginSection signUpSection">
        <div className="overlay"></div>
        <div className="row">
          <div className="col-md-8 flex1 flex-column part1">
            <h1>BEST UI/UX FASHION <br /> ECOMMERCE DASHBOARD & ADMIN PANEL</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, quos?
               Laborum recusandae quidem dolores expedita quod saepe 
              consectetur totam odit voluptate a ipsum, veritatis obcaecati et,
               natus libero unde dolorem.</p>
              <div className="w-100 mt-4">
              <Link to={'/'}><Button className="btn-blue btn-big"> <FaHome/> Go to home</Button></Link>
              </div>
          </div>
          <div className="col-md-4">
            <div className="signUpLeft pr-0">
              <div className="logo text-center">
                <h1>Raheeq</h1>
                <h5>Register a new account</h5>
              </div>
              <div className="loginBox mt-3">
                <div className="wrapper mt-3 card border">
                  <form onSubmit={signUp}>
                    <div
                      className={`form-group mb-3 position-relative ${
                        isFocus === 0 && "focus"
                      }`}
                    >
                      <span className="icon">
                        <FaUserCircle />
                      </span>
                      <input
                        onFocus={() => onFocus(0)}
                        onBlur={() => setIsFocus(null)}
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                        autoFocus
                        name="name"
                        onChange={onchangeInput}
                      />
                    </div>
                    <div
                      className={`form-group mb-3 position-relative ${
                        isFocus === 1 && "focus"
                      }`}
                    >
                      <span className="icon">
                        <MdEmail />
                      </span>
                      <input
                        onFocus={() => onFocus(1)}
                        onBlur={() => setIsFocus(null)}
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        name="email"
                        onChange={onchangeInput}
                      />
                    </div>
                    <div
                      className={`form-group mb-3 position-relative ${
                        isFocus === 2 && "focus"
                      }`}
                    >
                      <span className="icon">
                        <MdContactPhone />
                      </span>
                      <input
                        onFocus={() => onFocus(2)}
                        onBlur={() => setIsFocus(null)}
                        type="number"
                        className="form-control"
                        placeholder="Enter your phone"
                        name="phone"
                        onChange={onchangeInput}
                      />
                    </div>
                    <div
                      className={`form-group position-relative ${
                        isFocus === 3 && "focus"
                      }`}
                    >
                      <span className="icon">
                        <IoIosLock />
                      </span>
                      <input
                        onFocus={() => onFocus(3)}
                        onBlur={() => setIsFocus(null)}
                        type={`${
                          isShowPassword === true ? "text" : "password"
                        }`}
                        className="form-control"
                        placeholder="Enter your password"
                        name="password"
                        onChange={onchangeInput}
                      />
                      <span
                        className="toggleShowPassword"
                        onClick={() => setIsShowPassword(!isShowPassword)}
                      >
                        {isShowPassword === true ? <IoMdEyeOff /> : <IoMdEye />}
                      </span>
                    </div>
                    <div
                      className={`form-group position-relative ${
                        isFocus === 4 && "focus"
                      }`}
                    >
                      <span className="icon">
                        <IoShieldCheckmarkSharp />
                      </span>
                      <input
                        onFocus={() => onFocus(4)}
                        onBlur={() => setIsFocus(null)}
                        type={`${
                          isConfirmPassword === true ? "text" : "password"
                        }`}
                        className="form-control"
                        name="confirmPassword"
                        onChange={onchangeInput}
                        placeholder="Confirm your password"
                      />
                      <span
                        className="toggleShowPassword"
                        onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                      >
                        {isConfirmPassword === true ? (
                          <IoMdEyeOff />
                        ) : (
                          <IoMdEye />
                        )}
                      </span>
                    </div>
                    <div className="checkbox">
                      <input type="checkbox" />
                      <span className="ml-1">I agree with all Terms & Conditions</span>
                    </div>
                    <div className="form-group">
                    <Button type="submit" className="btn-blue btn-lg w-100 btn-big loadingIcon">
                  {isLoading === true ? <CircularProgress /> : "Sign Up "}
                </Button>
                    </div>
                    <div className="form-group text-center mb-0">
                      <div className="flex1 or mt-3 mb-3">
                        <span className="line"></span>
                        <span className="txt">or</span>
                        <span className="line"></span>
                      </div>
                      <Button
                        variant="outlined"
                        className="w-100 btn-big loginWithGoogle"
                      >
                        <FcGoogle /> Sign In with Google
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="wrapper mt-1 card border footer p-4">
                  <span className="text-center">
                    Already have an account?{" "}
                    <Link to={"/login"} className="link ml-1">
                      Sign In
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
