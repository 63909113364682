import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { createContext, useEffect, useState } from "react";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import ProductUpload from "./pages/Products/ProductUpload";
import AddCategory from "./pages/Category/AddCategory";
import Category from "./pages/Category/Category";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LoadingBar from 'react-top-loading-bar';
import Products from "./pages/Products/Products";
import EditCategory from "./pages/Category/EditCategory";
import EditProduct from "./pages/Products/EditProduct";
import { fetchDataFromApi } from "./utils/api";
import AddSubCat from "./pages/Category/AddSubCat";
import SubCategory from "./pages/Category/SubCategory";
import EditSubCat from "./pages/Category/EditSubCat";
import AddProductRAMS from "./pages/Products/AddProductRAMS";
import AddProductWeight from "./pages/Products/AddProductWeight";
import AddProductSize from "./pages/Products/AddProductSize";
import Orders from "./pages/Orders/Orders";
import AddHomeSlide from './pages/HomeBanner/AddHomeSlide';
import EditHomeSlide from './pages/HomeBanner/EditHomeSlide';
import HomeSlidesList from './pages/HomeBanner/HomeSlidesList';
import AddProductColor from "./pages/Products/AddProductColor";
import OrderDetails from "./pages/Orders/OrderDteals/OrderDetails";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ProtectedRoute from './ProtectedRoute';
import SalesChart from "./pages/SalesChart/SalesChart";
import ScreenSizeCheck from './ScreenSizeCheck';
import AdditionalDetails from "./pages/Products/AdditionalDetails";

const MyContext = createContext();

function App() {
  const [isToggleSidebar, setIsToggleSidebar] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isHideSidebarAndHeader, setIsHideSidebarAndHeader] = useState(false);
  const [themeMode, setThemeMode] = useState(true);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [user, setUser] = useState({
    name:"",
    email:"",
    userId: ""
  });
  const [baseUrl, setBaseUrl] = useState(`${process.env.REACT_APP_API_URL}`);
  const [alertBox, setAlertBox] = useState({
    msg: "",
    color: "",
    open: false
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertBox({
      open: false,
      msg: "",
      color: ""
    });
  };

  useEffect(() => {
    if (themeMode === true) {
      document.body.classList.remove('dark');
      document.body.classList.add('light');
      localStorage.setItem('themeMode', 'light');
    } else{
      document.body.classList.remove('light');
      document.body.classList.add('dark');
      localStorage.setItem('themeMode', 'dark');
    }
  }, [themeMode]);

  useEffect(()=>{
    const token = localStorage.getItem("token");

    if(token!=="" && token!==undefined  && token!==null){
      setIsLogin(true);

      const userData = JSON.parse(localStorage.getItem("user"));

      setUser(userData);
    
    }else{
      setIsLogin(false);
    }

  },[isLogin]);

  const fetchCategory = () => {
    if(isLogin === true){
      setProgress(20);
      const shop = JSON.parse(localStorage.getItem("shop"));
      fetchDataFromApi(`/api/category?shop=${shop._id}`).then((res) => {
        setCategoryData(res);
        setProgress(100);
      });
    }
  };

  const fetchSubCategory = () => {
    if(isLogin === true){
      const shop = JSON.parse(localStorage.getItem("shop"));
      setProgress(20);
      fetchDataFromApi(`/api/subCat?shop=${shop._id}`).then((res) => {
        setSubCatData(res);
        console.log(res);
        setProgress(100);
      });
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchSubCategory();
  },[]);

  const values = {
    isToggleSidebar,
    setIsToggleSidebar,
    isLogin,
    setIsLogin,
    isHideSidebarAndHeader,
    setIsHideSidebarAndHeader,
    setThemeMode,
    themeMode,
    alertBox,
    setAlertBox,
    setProgress,
    progress,
    baseUrl,
    setBaseUrl, 
    categoryData,
    fetchCategory,
    subCatData,
    setSubCatData,
    fetchSubCategory,
    setUser,
    user
  };

  return (
    <BrowserRouter>
      <MyContext.Provider value={values}>
        <LoadingBar
          color='#f11946'
          progress={progress}
          className="topLoadingBar"
          onLoaderFinished={() => setProgress(0)}
        />
        <Snackbar open={alertBox.open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={alertBox.color}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {alertBox.msg}
          </Alert>
        </Snackbar>
        {isHideSidebarAndHeader !== true && isSidebarVisible && <Header />}
        <div className="main d-flex">
        {isHideSidebarAndHeader !== true && isSidebarVisible && (
            <div className={`sidebarWrapper ${isToggleSidebar === true ? 'toggle' : ''}`}>
              <Sidebar />
            </div>
          )}
          <div className={`content ${isHideSidebarAndHeader === true && 'full'} ${isToggleSidebar === true ? 'toggle' : ''}`}>
            <Routes>
              {isLogin ? (
                <Route path="/*" element={<ScreenSizeCheck setSidebarVisible={setSidebarVisible}>
                  <Routes>
                    <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
                    <Route path="/product/details/:id" element={<ProtectedRoute><ProductDetails /></ProtectedRoute>} />
                    <Route path="/product/upload" element={<ProtectedRoute><ProductUpload /></ProtectedRoute>} />
                    <Route path="/product/edit/:id" element={<ProtectedRoute><EditProduct /></ProtectedRoute>} />
                    <Route path="/productRAMS/add" element={<ProtectedRoute><AddProductRAMS /></ProtectedRoute>} />
                    <Route path="/productWEIGHT/add" element={<ProtectedRoute><AddProductWeight /></ProtectedRoute>} />
                    <Route path="/productSIZE/add" element={<ProtectedRoute><AddProductSize /></ProtectedRoute>} />
                    <Route path="/productCOLOR/add" element={<ProtectedRoute><AddProductColor /></ProtectedRoute>} />
                    <Route path="/additionalDetails/add" element={<ProtectedRoute><AdditionalDetails /></ProtectedRoute>} />
                    {/* <Route path="/category" element={<ProtectedRoute><Category /></ProtectedRoute>} /> */}
                    <Route path="/subCategory" element={<ProtectedRoute><SubCategory /></ProtectedRoute>} />
                    {/* <Route path="/category/add" element={<ProtectedRoute><AddCategory /></ProtectedRoute>} /> */}
                    <Route path="/subCategory/add" element={<ProtectedRoute><AddSubCat /></ProtectedRoute>} />
                    {/* <Route path="/category/edit/:id" element={<ProtectedRoute><EditCategory /></ProtectedRoute>} /> */}
                    <Route path="/subCategory/edit/:id" element={<ProtectedRoute><EditSubCat /></ProtectedRoute>} />
                    <Route path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
                    <Route path="/salesChart" element={<ProtectedRoute><SalesChart /></ProtectedRoute>} />
                    <Route path="/order/details/:id" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
                    <Route path="/homeBannerSlide/add" element={<ProtectedRoute><AddHomeSlide /></ProtectedRoute>} />
                    <Route path="/homeBannerSlide/list" element={<ProtectedRoute><HomeSlidesList /></ProtectedRoute>} />
                    <Route path="/homeBannerSlide/edit/:id" element={<ProtectedRoute><EditHomeSlide /></ProtectedRoute>} />
                    <Route path="/*" element={<ProtectedRoute><PageNotFound /></ProtectedRoute>} />
                  </Routes>
                </ScreenSizeCheck>} />
              ) : (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signUp" element={<SignUp />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      </MyContext.Provider>
    </BrowserRouter>
  );
}

export default App;
export { MyContext };
