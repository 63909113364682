import React, { useRef } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { deleteData, fetchDataFromApi } from "../../utils/api";
import { MdFilterVintage } from "react-icons/md";
import UserAvatarImg from "../../components/UserAvatarImg/UserAvatarImg";
import { MdBrandingWatermark } from "react-icons/md";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { Button, Rating } from "@mui/material";
import { FaReply } from "react-icons/fa";
import { useEffect } from "react";
import { MdRateReview } from "react-icons/md";
import { BsPatchCheckFill } from "react-icons/bs";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductZoom from "../../components/ProductZoom/ProductZoom";
import moment from 'moment';
import axios from "axios";

const formatDate = (isoDate) => {
  return moment(isoDate).format('DD/MM/YYYY hh:mm A');
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const ProductDetails = () => {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      Products
    </Link>,
    <Typography key="3" color="text.primary">
      Product View
    </Typography>,
  ];

  const [productData, setProductData] = useState([]);
    const [reviewsData, setreviewsData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchProduct = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/staticId/${id}`);
            setProductData(res?.data);
          } catch (err) {
            if (axios.isAxiosError(err)) {
              if (err.response && err.response.status === 404) {
                navigate('/page-not-found');
              } else {
                setError('An unexpected error occurred');
              }
            } else {
              setError('An unexpected error occurred');
            }
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchProduct();
      }, [id, navigate]);
    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            fetchDataFromApi(`/api/productReviews?staticId=${id}`).then((res) => {
                setreviewsData(res)
            })
        } catch (err) {
            if (axios.isAxiosError(err)) {
              if (err.response && err.response.status === 404) {
                navigate('/product/error');
              } else {
                setError('An unexpected error occurred');
              }
            } else {
              setError('An unexpected error occurred');
            }
          } finally {
            setIsLoading(false);
          }

    }, [id, navigate]);

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 flex-row p-4">
          <h5>Product View</h5>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className="ml-auto"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className='card productDetailsSEction'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className="sliderWrapper pt-3 pb-3 pl-4 pr-4">
                                <h6 className="mb-4">Product Gallery</h6>
                                <ProductZoom images={productData?.images} discount={productData?.discount} />
                            </div>
                        </div>

                        <div className='col-md-7'>
                            <div className=" pt-3 pb-3 pl-4 pr-4">
                                <h6 className="mb-4">Product Details</h6>

                                <h4>{productData?.name}</h4>

                                <div className="productInfo mt-4">
                                    <div className="row mb-2">
                                        <div className="col-sm-3 d-flex align-items-center">
                                            <span className="icon"><MdBrandingWatermark /></span>
                                            <span className="name">Brand</span>
                                        </div>

                                        <div className="col-sm-9">

                                            :   <span>{productData?.brand}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3 d-flex align-items-center">
                                            <span className="icon"><BiSolidCategoryAlt /></span>
                                            <span className="name">Category</span>
                                        </div>

                                        <div className="col-sm-9">

                                            : <span>{productData?.catName}</span>
                                        </div>
                                    </div>

                                    {
                                        productData?.productRam?.length !== 0 &&
                                        <div className="row">
                                            <div className="col-sm-3 d-flex align-items-center">
                                                <span className="icon"><MdFilterVintage /></span>
                                                <span className="name">RAM</span>
                                            </div>

                                            <div className="col-sm-9">

                                                : <span>
                                                    <div className="row">
                                                        <ul className="list list-inline tags sml">

                                                            {
                                                                productData?.productRam?.map((item, index) => {
                                                                    return (
                                                                        <li className="list-inline-item">
                                                                            <span>{item}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }


                                                        </ul>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    }


                                    {
                                        productData?.size?.length !== 0 &&
                                        <div className="row">
                                            <div className="col-sm-3 d-flex align-items-center">
                                                <span className="icon"><MdFilterVintage /></span>
                                                <span className="name">SIZE</span>
                                            </div>

                                            <div className="col-sm-9">

                                                : <span>
                                                    <div className="row">
                                                        <ul className="list list-inline tags sml">

                                                            {
                                                                productData?.size?.map((item, index) => {
                                                                    return (
                                                                        <li className="list-inline-item">
                                                                            <span>{item}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }


                                                        </ul>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        productData?.productWeight?.length !== 0 &&
                                        <div className="row">
                                            <div className="col-sm-3 d-flex align-items-center">
                                                <span className="icon"><MdFilterVintage /></span>
                                                <span className="name">Weight</span>
                                            </div>

                                            <div className="col-sm-9">

                                                : <span>
                                                    <div className="row">
                                                        <ul className="list list-inline tags sml">

                                                            {
                                                                productData?.productWeight?.map((item, index) => {
                                                                    return (
                                                                        <li className="list-inline-item">
                                                                            <span>{item}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }


                                                        </ul>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    }


                                    <div className="row">
                                        <div className="col-sm-3 d-flex align-items-center">
                                            <span className="icon"><MdRateReview /></span>
                                            <span className="name">Review</span>
                                        </div>

                                        <div className="col-sm-9">

                                            : <span>({reviewsData?.length}) Review</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3 d-flex align-items-center">
                                            <span className="icon"><BsPatchCheckFill /></span>
                                            <span className="name">Published</span>
                                        </div>

                                        <div className="col-sm-9">

                                            : <span>{productData?.createdAt}</span>
                                        </div>
                                    </div>




                                </div>

                            </div>

                        </div>
                    </div>


                    <div className="p-4">
                        <h6 className="mt-4 mb-3">Product Description</h6>
                        <p>{productData?.description}</p>


                        <br />

                        {
                            /*
                                 <h6 className="mt-4 mb-4">Rating Analytics</h6>
        
                                <div className="ratingSection">
                                    <div className="ratingrow d-flex align-items-center">
                                        <span className="col1">
                                            5 Star
                                        </span>
        
                                        <div className="col2">
                                            <div className="progress">
                                                <div className="progress-bar" style={{ width: '70%' }}></div>
                                            </div>
                                        </div>
        
                                        <span className="col3">
                                            (22)
                                        </span>
                                    </div>
        
                                    <div className="ratingrow d-flex align-items-center">
                                        <span className="col1">
                                            4 Star
                                        </span>
        
                                        <div className="col2">
                                            <div className="progress">
                                                <div className="progress-bar" style={{ width: '50%' }}></div>
                                            </div>
                                        </div>
        
                                        <span className="col3">
                                            (22)
                                        </span>
                                    </div>
        
                                    <div className="ratingrow d-flex align-items-center">
                                        <span className="col1">
                                            3 Star
                                        </span>
        
                                        <div className="col2">
                                            <div className="progress">
                                                <div className="progress-bar" style={{ width: '50%' }}></div>
                                            </div>
                                        </div>
        
                                        <span className="col3">
                                            (2)
                                        </span>
                                    </div>
        
        
        
                                    <div className="ratingrow d-flex align-items-center">
                                        <span className="col1">
                                            2 Star
                                        </span>
        
                                        <div className="col2">
                                            <div className="progress">
                                                <div className="progress-bar" style={{ width: '20%' }}></div>
                                            </div>
                                        </div>
        
                                        <span className="col3">
                                            (2)
                                        </span>
                                    </div>
        
        
                                    <div className="ratingrow d-flex align-items-center">
                                        <span className="col1">
                                            1 Star
                                        </span>
        
                                        <div className="col2">
                                            <div className="progress">
                                                <div className="progress-bar" style={{ width: '50%' }}></div>
                                            </div>
                                        </div>
        
                                        <span className="col3">
                                            (2)
                                        </span>
                                    </div>
        
                                </div>
        
                            */
                        }


                        <br />


                        {
                            reviewsData?.length !== 0 &&
                            <>
                                <h6 className="mt-4 mb-4">Customer Reviews</h6>

                                <div className="reviewsSecrion">

                                    {
                                        reviewsData?.length !== 0 && reviewsData?.map((item, index) => {
                                            const formattedDate = formatDate(item?.dateCreated); 
                                            return (
                                                <div className='reviewBox mb-4 border-bottom' key={index}>

                                                            <div className='info'>
                                                                <div className='d-flex align-items-center w-100'>
                                                                    <div className="d-flex align-items-center w-100">
                                                                    <img width="40" height="40" src="https://img.icons8.com/fluency/48/user-male-circle--v1.png" alt="user-male-circle--v1" className="mr-1"/>
                                                                    <h5>{item?.customerName}</h5>
                                                                    </div>

                                                                    <div className='ml-auto'>
                                                                        <Rating name="half-rating-read"
                                                                            value={item?.customerRating} readOnly size="small" />
                                                                    </div>
                                                                </div>

                                                                <h6>{formattedDate}</h6>

                                                                <p>{item?.review} </p>
                                                            </div>

                                                        </div>
                                            )
                                        })
                                    }




                                </div>
                            </>
                        }



                    </div>

                </div>
      </div>
    </>
  );
};

export default ProductDetails;
