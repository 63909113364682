import {
  Breadcrumbs,
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Rating,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { MdShoppingBag } from "react-icons/md";
import { Link } from "react-router-dom";
import { HiOutlinePlus } from "react-icons/hi2";
import { deleteData, fetchDataFromApi } from "../../utils/api";
import { MyContext } from "../../App";
import DashboardBox from "../Dashboard/components/DashboardBox";
import { MdCategory } from "react-icons/md";

import { IoShieldCheckmarkSharp } from "react-icons/io5";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Products = () => {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/"}
      onClick={handleClick}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={handleClick}
    >
      Category
    </Link>,
    <Typography key="3" color="text.primary">
      Category List
    </Typography>,
  ];
  const [showBy, setShowBy] = useState("");
  const [showCatBy, setShowCatBy] = useState("");
  const [brandBy, setBrandBy] = useState("");
  const context = useContext(MyContext);
  const [productList, setProductList] = useState([]);
  const [totalProducts, setTotalProducts] = useState();
  const [totalCategory, setTotalCategory] = useState();
  const [totalSubCategory, setTotalSubCategory] = useState();
  const [categoryVal, setcategoryVal] = useState('all');

  const [products, setProducts] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    context.setProgress(30);
    const shop = JSON.parse(localStorage.getItem("shop"))
    fetchDataFromApi(`/api/products?shop=${shop._id}&?page=1&perPage=8`).then((res) => {
      setProducts(res);
      console.log(res)
      context.setProgress(100);

    fetchDataFromApi("/api/category/get/count").then((res) => {
        setTotalCategory(res.categoryCount);
    })

    fetchDataFromApi("/api/subCat/get/count").then((res) => {
        setTotalSubCategory(res.subCatCount);
    })
    });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const shop = JSON.parse(localStorage.getItem("shop"));
        fetchDataFromApi(`/api/products?shop=${shop?._id}`).then((res) => {
          setProducts(res);
          console.log(res)
          setTotalProducts(res?.products?.length);
        });
      } catch (error) {
        console.error("Error fetching data:", error.response.data);
      }
    };

    fetchData();

  }, []);

  const deleteProduct = (id) => {
    context.setProgress(30);
    deleteData(`/api/products/${id}`).then((res) => {
      context.setProgress(100);
      context.setAlertBox({
        open: true,
        msg: "Product deleted successfully",
        color: "success",
      });
      window.location.reload();
      fetchDataFromApi("/api/products").then((res) => {
        setProductList(res);
      });
    });
  };

  const handleChange = (e, value) => {
    context.setProgress(20);
    fetchDataFromApi(`/api/products?page=${value}&perPage=8`).then((res) => {
      setProductList(res);
      context.setProgress(100);
    });
  };
  const showPerPage = (e) => {
    setShowBy(e.target.value);
    fetchDataFromApi(`/api/products?page=${1}&perPage=${e.target.value}`).then((res) => {
        setProductList(res);
        context.setProgress(100);
    })
}
const handleChangeCategory = (event) => {
  if (event.target.value !== "all") {
      setcategoryVal(event.target.value);
      fetchDataFromApi(`/api/products?category=${event.target.value}`).then((res) => {
          setProductList(res);
          context.setProgress(100);
      })
  }
  if (event.target.value === "all") {
      setcategoryVal(event.target.value);
      fetchDataFromApi(`/api/products?page=${1}&perPage=${8}`).then((res) => {
          setProductList(res);
          context.setProgress(100);
      })
  }

};

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 flex-row p-4">
          <Link to={"/product/upload"}>
            <Button className="btn-blue btn-lg addCatBtn mr-3">
              <HiOutlinePlus /> &nbsp; Add New Product
            </Button>
          </Link>
          <div className="ml-auto flex2">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              className="ml-auto"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
        <div className="row dashboardBoxWrapperRow dashboardBoxWrapperRowV2 pt-0">
                    <div className="col-md-12">
                        <div className="dashboardBoxWrapper d-flex">
                            <DashboardBox color={["#1da256", "#48d483"]} icon={<MdShoppingBag />} title="Total Products" count={totalProducts} grow={true} />
                            <DashboardBox color={["#c012e2", "#eb64fe"]} icon={<MdCategory />}
                                title="Total Categories" count={totalCategory}
                            />
                            <DashboardBox color={["#2c78e5", "#60aff5"]} icon={<IoShieldCheckmarkSharp />} title="Total Sub Category" count={totalSubCategory} />

                        </div>
                    </div>


                </div>
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Best Selling Products</h3>
          <div className="row cardFilters mt-3">
                        <div className="col-md-3">
                            <h4>SHOW BY</h4>
                            <FormControl size="small" className="w-100">
                                <Select
                                    value={showBy}
                                    onChange={showPerPage}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    labelId="demo-select-small-label"
                                    className="w-100"
                                >
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={60}>60</MenuItem>
                                    <MenuItem value={70}>70</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-3">
                            <h4>CATEGORY BY</h4>
                            <FormControl size="small" className="w-100">
                                <Select
                                    value={categoryVal}
                                    onChange={handleChangeCategory}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className='w-100'
                                >
                                    <MenuItem value="all">
                                        <em value={"all"}>All</em>
                                    </MenuItem>
                                    {
                                        context.categoryData?.categoryList?.length !== 0 && context.categoryData?.categoryList?.map((cat, index) => {
                                            return (
                                                <MenuItem className="text-capitalize" value={cat.id} key={index}>{cat.name}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </div>


                    </div>
          <div className="table-responsive mt-3">
            <table className="table table-bordered v-align">
              <thead className="thead-dark">
                <tr>
                  <th>UID</th>
                  <th style={{ width: "300px" }}>PRODUCT</th>
                  <th>CATEGORY</th>
                  <th>SUB CATEGORY</th>
                  <th>BRAND</th>
                  <th>PRICE</th>
                  <th>STOCK</th>
                  <th>RATING</th>
                  <th>DISCOUNT</th>
                  <th>PRODUCT RAMS</th>
                  <th>PRODUCT WEIGHT</th>
                  <th>PRODUCT SIZE</th>
                  <th>PRODUCT COLOR</th>
                  <th>PRODUCT detail</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {products?.products?.length === 0 ? (
                  <p className="font-weight-bold mb-3 m-3">
                    No items to display
                  </p>
                ) : (
                  products?.products?.map((item, index) => (
                    <tr key={index}>
                      <td>#{index + 1}</td>
                      <td>
                        <div className="flex2 productBox">
                          <div className="imgWrapper">
                            <div className="img">
                              <img
                                src={`${item.images[0]}`}
                                className="w-100"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="info">
                            <h6>{item.name}</h6>

                            <p>{item.description}...</p>
                          </div>
                        </div>
                      </td>
                      {item?.category?.map((cat, index) => (
                      <td key={index}>{cat.name}</td>
                      ))}
                      {item?.subCat?.map((subCat, index) => (
                      <td key={index}>{subCat.subCat}</td>
                      ))}
                      <td>{item.brand}</td>
                      <td>
                        <del className="oldPrice">{item.oldPrice}</del>
                        <span className="newPrice text-danger">
                          {item.price}
                        </span>
                      </td>
                      <td>{item.countInStock}</td>
                      <td>
                        <Rating
                          name="read-only"
                          defaultValue={item.rating}
                          readOnly
                          precision={0.5}
                          size="small"
                        />
                      </td>
                      <td>{item?.discount}</td>
                      <td>
                        {item?.productRam?.map((ram) => {
                          return (
                            <span className="badge badge-primary mr-2">
                              {ram}
                            </span>
                          );
                        })}
                      </td>
                      <td>
                        {item?.productWeight?.map((weight) => {
                          return (
                            <span className="badge badge-primary mr-2">
                              {weight}
                            </span>
                          );
                        })}
                      </td>
                      <td>
                        {item?.size?.map((size) => {
                          return (
                            <span className="badge badge-primary mr-2">
                              {size}
                            </span>
                          );
                        })}
                      </td>
                      <td>
                        {item?.color?.map((color) => {
                          return (
                            <span className="badge badge-primary mr-2">
                              {color}
                            </span>
                          );
                        })}
                      </td>
                      <td>
                        {item?.detail?.map((color) => {
                          return (
                            <span className="badge badge-primary mr-2">
                              {color}
                            </span>
                          );
                        })}
                      </td>
                      <td>
                        <div className="actions flex2">
                          <Link to={`/product/details/${item?.staticId}`}>
                            <Button className="secondary" color="secondary">
                              <IoEye />
                            </Button>
                          </Link>
                          <Link to={`/product/edit/${item?.id}`}>
                            <Button className="success" color="success">
                              <FaEdit />
                            </Button>
                          </Link>
                          <Button
                            className="error"
                            color="error"
                            onClick={() => deleteProduct(item.id)}
                          >
                            <IoMdTrash />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {products?.totalPages > 1 && (
              <div className="d-flex tableFooter">
                <p>
                  Showing <b>1</b> of <b>8</b> results
                </p>
                <Pagination
                  count={products?.totalPages}
                  className="pagination"
                  showFirstButton
                  showLastButton
                  color="primary"
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
