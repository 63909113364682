import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { MdMenuOpen } from "react-icons/md";
import SearchBox from "../SearchBox/SearchBox";
import { MdLightMode } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { IoIosMenu, IoMdCart } from "react-icons/io";
import { FaBell } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { LockReset, Person } from "@mui/icons-material";
import { MyContext } from "../../App";
import UserAvatarImg from "../UserAvatarImg/UserAvatarImg";
import logo from "../../assests/hibuylogo.png";
import { fetchDataFromApi } from "../../utils/api";
import axios from "axios";

const Header = () => {
  const context = useContext(MyContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenNotificationDrop, setIsOpenNotificationDrop] = useState(false);
  const openMyAcc = Boolean(anchorEl);
  const openNotification = Boolean(isOpenNotificationDrop);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastNotificationTimestamp, setLastNotificationTimestamp] = useState(0);

  useEffect(() => {
    if (context.isLogin === true) {
      const shop = JSON.parse(localStorage.getItem("shop"));
      fetchDataFromApi(`/api/orders?shop=${shop._id}`).then((res) => {
        setNotifications(res);
      });
    }
  }, [context.isLogin]);

   const markNotificationsAsRead = async () => {
    try {
      const { userId } = JSON.parse(localStorage.getItem("user"))
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/notifications/markAsRead`, { userId });
      return response.data;
    } catch (error) {
      console.error('Error marking notifications as read:', error);
      throw error;
    }
  };
  
  useEffect(() => {
    const unreadNotifications = notifications.filter(
      (notification) => !notification.isRead
    );
    setUnreadCount(unreadNotifications.length);
  
    if (
      unreadNotifications.length > 0 &&
      lastNotificationTimestamp < new Date().getTime() - 1000
    ) {
      playNotificationSound();
      setLastNotificationTimestamp(new Date().getTime());
    }
  }, [unreadCount, setUnreadCount])

  const markAllAsRead = async () => {
    await markNotificationsAsRead();
    setUnreadCount(0);
  };

  const playNotificationSound = () => {
    const audio = new Audio("notification.mp4"); 
    audio.play();
  };

  const handleClickMyAcc = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickNotification = (event) => {
    setIsOpenNotificationDrop(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotificationDrop = () => {
    setIsOpenNotificationDrop(false);
    markAllAsRead();
  };
  const logoutUser = () => {
    localStorage.clear();
    context.setAlertBox({
      open: true,
      color: "success",
      msg: "Successfully Logout!",
    });
    window.location.href = "/login";
  };
  return (
    <>
      <header className="flex2">
        <div className="container-fluid">
          <div className="row flex2 w-100">
            {/* Logo wrapper */}
            <div className="col-sm-2 part1">
              <div className="logo ml-4">
                <div className="logo-wrapper">
                  <img src={logo} className="text-center" alt="logo" />
                </div>
              </div>
            </div>
            <div className="col-sm-3 flex2 part2">
              <Button
                className="rounded-circle mr-3"
                onClick={() =>
                  context.setIsToggleSidebar(!context.isToggleSidebar)
                }
              >
                {context.isToggleSidebar === true ? (
                  <IoIosMenu />
                ) : (
                  <MdMenuOpen />
                )}
              </Button>
              <SearchBox />
            </div>
            <div className="col-sm-7 flex3 part3">
              {/* <Button className="rounded-circle mr-3" onClick={() => context.setThemeMode(!context.themeMode)}>
                <MdLightMode />
              </Button>
              <Button className="rounded-circle mr-3">
                <TbWorld />
              </Button>
              <Button className="rounded-circle mr-3">
                <IoMdCart />
              </Button>

              <Button className="rounded-circle mr-3">
                <MdEmail />
              </Button> */}
              <Button
                onClick={handleClickNotification}
                className="rounded-circle mr-3"
              >
                <FaBell />
                {unreadCount > 0 && (
                  <span className="notificationBadge">{unreadCount}</span>
                )}
              </Button>
              <div className="notificationWrapper position-relative">
                <Menu
                  anchorEl={isOpenNotificationDrop}
                  className="notifications dropdown_list"
                  id="notifications"
                  open={openNotification}
                  onClose={handleCloseNotificationDrop}
                  onClick={handleCloseNotificationDrop}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div className="head pl-3 pb-0">
                    <h4>Orders (12) </h4>
                  </div>
                  <Divider className="mb-1" />
                  <div className="scroll">
                    {notifications.length === 0 ? (
                      <MenuItem>No notifications</MenuItem>
                    ) : (
                      notifications.map((notification, index) => (
                        <MenuItem
                          key={index}
                          onClick={handleCloseNotificationDrop}
                        >
                          <div className="d-flex">
                            <div>
                              <div className="userImg">
                                <span className="rounded-circle">
                                  <img
                                    src={
                                      notification.userAvatar || "/avatar.png"
                                    }
                                    alt=""
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="dropdown_info">
                              <h4>
                                <p>
                                  <span>
                                    <b>{notification.name}</b> made a new order of &nbsp;
                                    {
                                      notification?.products?.map((item,index) => (
                                        <b key={index}>{item.productTitle?.substr(0, 20) + '...'}</b>
                                      ))
                                    }
                                  </span>
                                </p>
                              </h4>
                              <p className="text-sky">{notification.timeAgo}</p>
                            </div>
                          </div>
                        </MenuItem>
                      ))
                    )}
                  </div>
                  <div className="pl-2 pr-2 w-100 pt-2 pb-1">
                    <Button className="btn-blue w-100">
                      View all notifications
                    </Button>
                  </div>
                </Menu>
              </div>
              {context.isLogin !== true ? (
                <Link to={"/login"}>
                  <Button className="btn-blue btn-lg btn-round">Sign In</Button>
                </Link>
              ) : (
                <div className="myAccWrapper">
                  <Button onClick={handleClickMyAcc} className="myAcc flex2">
                    <div className="userImg">
                      <span className="rounded-circle">
                        {/* <img src="/avatar.png" alt="" /> */}
                        {context.user?.name?.charAt(0)}
                      </span>
                    </div>
                    <div className="userInfo">
                      <h4>{context.user?.name}</h4>
                      <p className="mb-0">{context.user?.email}</p>
                    </div>
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMyAcc}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={handleClose}>
                      <Person className="mr-2" fontSize="small" /> My account
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <LockReset className="mr-2" fontSize="small" /> Reset
                      Password
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={logoutUser}>
                      <Logout className="mr-2" fontSize="small" />
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
