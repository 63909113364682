import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import axios from 'axios';

const xLabels = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const SalesChart = () => {
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    const fetchMonthlySales = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/monthly-sales`); // Adjust API endpoint as per your backend setup
        setSalesData(response.data);
        console.log(response)
      } catch (error) {
        console.error('Error fetching monthly sales:', error);
      }
    };

    fetchMonthlySales();
  }, []);

  // Prepare data for the chart
  const pData = xLabels.map((label, index) => {
    const monthData = salesData.find(item => item._id.month === index + 1);
    return monthData ? monthData.totalSales : 0;
  });

  const uData = [4000]; // Example data for another series (if needed)

  return (
    <BarChart
      width={700} // Adjust width as per your design
      height={400} // Adjust height as per your design
      series={[
        { data: pData, label: 'Sales', id: 'salesId' },
        // Add more series if needed, e.g., { data: uData, label: 'Users', id: 'usersId' }
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
  );
};

export default SalesChart;
