import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Rating,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FaCloudUploadAlt, FaRegImages } from "react-icons/fa";
import {
  deleteData,
  deleteImages,
  fetchDataFromApi,
  postData,
  uploadImage,
} from "../../utils/api";
import { MyContext } from "../../App";
import { IoCloseSharp, IoImagesOutline } from "react-icons/io5";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const ProductUpload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/"}
      onClick={handleClick}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={handleClick}
    >
      Products
    </Link>,
    <Typography key="3" color="text.primary">
      Product Upload
    </Typography>,
  ];
  const [category, setCategory] = useState([]);
  const [subCat, setSubCat] = useState("");
  const [isFeaturedVal, setIsFeaturedVal] = useState(null);
  const [productFor, setProductFor] = useState("");
  const [itemFor, setItemFor] = useState("");
  const context = useContext(MyContext);
  const [productImagesArr, setProductImagesArr] = useState([]);
  const [files, setFiles] = useState([]);
  const [imgFiles, setImgFile] = useState();
  const navigate = useNavigate();
  const [preview, setPreview] = useState([]);
  const [uploading, setUploading] = useState(false);
  const productImages = useRef();
  const [productWeight, setProductWeight] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productRams, setProductRAMS] = useState([]);
  const [productRAMSData, setProductRAMSData] = useState([]);
  const [productWEIGHTData, setProductWEIGHTData] = useState([]);
  const [productSIZEData, setProductSIZEData] = useState([]);
  const [productCOLORData, setProductCOLORData] = useState([]);
  const [additionalDetailsData, setAdditionalDetailsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [details, setDetails] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
  const [newDetail, setNewDetail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    context.setProgress(20);
    const shop = JSON.parse(localStorage.getItem("shop"));
    fetchDataFromApi(`/api/subCat?shop=${shop._id}`).then((res) => {
      setSubCatData(res);
      context.setProgress(100);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    context.setProgress(20);
    fetchDataFromApi(`/api/category`).then((res) => {
      setCategoryData(res);
      context.setProgress(100);
    });
  }, []);

  const shop = JSON.parse(localStorage.getItem("shop"));
  const [formFields, setFormFields] = useState({
    name: "",
    description: "",
    brand: "",
    productFor: "",
    itemFor: "",
    price: null,
    oldPrice: null,
    expense: null,
    category: "",
    subCat: "",
    countInStock: null,
    catName: "",
    subCatName: "",
    subCatId: "",
    rating: 0,
    isFeatured: null,
    discount: null,
    productRam: [],
    size: [],
    color: [],
    detail: [],
    keyword: [],
    productWeight: [],
    shop: shop,
  });
  const formData = new FormData();

  const calculateDiscount = (price, oldPrice) => {
    if (oldPrice > 0 && price > 0) {
      return Math.floor(((oldPrice - price) / oldPrice) * 100)
    }
    return 0;
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setFormFields(prevState => {
      const updatedFields = { ...prevState, [name]: value };
      if (name === "price" || name === "oldPrice") {
        const discount = calculateDiscount(updatedFields.price, updatedFields.oldPrice);
        updatedFields.discount = discount;
      }
      return updatedFields;
    });
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    setFormFields(() => ({
      ...formFields,
      category: event.target.value,
    }));
  };
  const handleChangeSubCategory = (event) => {
    setSubCat(event.target.value);
    setFormFields(() => ({
      ...formFields,
      subCat: event.target.value,
    }));
    formFields.subCatId = event.target.value;
  };
  const handleChangeIsFeatured = (event) => {
    setIsFeaturedVal(event.target.value);
    setFormFields(() => ({
      ...formFields,
      isFeatured: event.target.value,
    }));
  };
  const handleChangeProductFor = (event) => {
    setProductFor(event.target.value);
    setFormFields(() => ({
      ...formFields,
      productFor: event.target.value,
    }));
  };
  const handleChangeItemFor = (event) => {
    setItemFor(event.target.value);
    setFormFields(() => ({
      ...formFields,
      itemFor: event.target.value,
    }));
  };
  const [ratingValue, setRatingValue] = useState(2);
  useEffect(() => {
    window.scrollTo(0, 0);
    setSubCat(context.categoryData);
    fetchDataFromApi("/api/imageUpload").then((res) => {
      res?.map((item) => {
        item?.images?.map((img) => {
          deleteImages(`/api/category/deleteImage?img=${img}`).then((res) => {
            deleteData("/api/imageUpload/deleteAllImages");
          });
        });
      });
    });

    fetchDataFromApi(`/api/productWeight?shop=${shop._id}`).then((res) => {
      setProductWEIGHTData(res);
    });
    fetchDataFromApi(`/api/productRAMS?shop=${shop._id}`).then((res) => {
      setProductRAMSData(res);
    });
    fetchDataFromApi(`/api/productSIZE?shop=${shop._id}`).then((res) => {
      setProductSIZEData(res);
    });
    fetchDataFromApi(`/api/productCOLOR?shop=${shop._id}`).then((res) => {
      setProductCOLORData(res);
    });
    fetchDataFromApi(`/api/additionalDetails?shop=${shop._id}`).then((res) => {
      setAdditionalDetailsData(res);
    });
  }, []);

  const changeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeProductRams = (event) => {
    // setProductRAMS(event.target.value);
    // setFormFields(() => ({
    //     ...formFields,
    //     productRam: event.target.value
    // }))

    const {
      target: { value },
    } = event;
    setProductRAMS(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    formFields.productRam = value;
  };

  const handleChangeProductWeight = (event) => {
    const {
      target: { value },
    } = event;
    setProductWeight(typeof value === "string" ? value.split(",") : value);

    formFields.productWeight = value;
  };

  const handleChangeProductSize = (event) => {
    const {
      target: { value },
    } = event;
    setProductSize(
      typeof value === "string" ? value.split(",") : value
    );

    formFields.size = value;
  };
  const handleChangeProductColor = (event) => {
    const {
      target: { value },
    } = event;
    setProductColor(typeof value === "string" ? value.split(",") : value);

    formFields.color = value;
  };

  const createAdditionalDetail = async (detail, shop) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/additionalDetails/create`,
        { detail, shop }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating additional detail:", error);
      throw error;
    }
  };
  const createKeywords = async (keyword, shop) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/keywords/create`,
        { keyword, shop }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating search keywords:", error);
      throw error;
    }
  };

  const deleteAdditionalDetail = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/additionalDetails/${id}`
      );
    } catch (error) {
      console.error("Error deleting additional detail:", error);
      throw error;
    }
  };

  const deleteKeywords = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/keywords/${id}`
      );
    } catch (error) {
      console.error("Error deleting additional detail:", error);
      throw error;
    }
  };

  const handleAddDetail = async () => {
    if (newDetail.trim() === "") return;
    try {
      const addedDetail = await createAdditionalDetail(newDetail, shop._id);
      setDetails([...details, addedDetail]);
      setNewDetail("");
    } catch (error) {
      console.error("Error adding detail:", error);
    }
  };

  const handleAddKeywords = async () => {
    if (newKeyword.trim() === "") return;
    console.log(newKeyword)
    try {
      const addedKeywords = await createKeywords(newKeyword, shop._id);
      setKeywords([...keywords, addedKeywords]);
      setNewKeyword("");
    } catch (error) {
      console.error("Error adding keywords:", error);
    }
  };

  const handleDeleteDetail = async (id) => {
    try {
      await deleteAdditionalDetail(id);
      setDetails(details.filter((detail) => detail._id !== id));
    } catch (error) {
      console.error("Error deleting detail:", error);
    }
  };
  const handleDeleteKeyword = async (id) => {
    try {
      await deleteKeywords(id);
      setKeywords(keywords.filter((keyword) => keyword._id !== id));
    } catch (error) {
      console.error("Error deleting keyword:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddDetail();
    }
  };
  const handleKeywordKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddKeywords();
    }
  };

  const selectCat = (cat) => {
    formFields.catName = cat;
  };
  const selectSubCat = (subCat) => {
    formFields.subCatName = subCat;
  };

  let img_arr = [];
  let uniqueArray = [];

  const onChangeFile = async (e, apiEndPoint) => {
    try {
      const files = e.target.files;
      setUploading(true);

      //const fd = new FormData();
      for (var i = 0; i < files.length; i++) {
        // Validate file type
        if (
          files[i] &&
          (files[i].type === "image/jpeg" ||
            files[i].type === "image/jpg" ||
            files[i].type === "image/png" ||
            files[i].type === "image/webp")
        ) {
          const file = files[i];

          formData.append(`images`, file);
        } else {
          context.setAlertBox({
            open: true,
            error: true,
            msg: "Please select a valid JPG or PNG image file.",
          });

          setUploading(false);
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }

    uploadImage(apiEndPoint, formData).then((res) => {
      fetchDataFromApi("/api/imageUpload").then((response) => {
        if (
          response !== undefined &&
          response !== null &&
          response !== "" &&
          response.length !== 0
        ) {
          response.length !== 0 &&
            response.map((item) => {
              item?.images.length !== 0 &&
                item?.images?.map((img) => {
                  img_arr.push(img);

                  //console.log(img)
                });
            });

          uniqueArray = img_arr.filter(
            (item, index) => img_arr.indexOf(item) === index
          );

          const appendedArray = [...preview, ...uniqueArray];

          setPreview(appendedArray);

          setTimeout(() => {
            setUploading(false);
            img_arr = [];
            context.setAlertBox({
              open: true,
              error: false,
              msg: "Images Uploaded!",
            });
          }, 500);
        }
      });
    });
  };

  const removeImg = async (index, imgUrl) => {
    const imgIndex = preview.indexOf(imgUrl);

    deleteImages(`/api/category/deleteImage?img=${imgUrl}`).then((res) => {
      context.setAlertBox({
        open: true,
        error: false,
        msg: "Image Deleted!",
      });
    });

    if (imgIndex > -1) {
      preview.splice(index, 1);
    }
  };

  useEffect(() => {
    if (!imgFiles) return;
    let tmp = [];
    for (let i = 0; i < imgFiles.length; i++) {
      tmp.push(URL.createObjectURL(imgFiles[i]));
    }

    const objectUrls = tmp;
    setPreview(objectUrls);
    // for free memory
    for (let i = 0; i < objectUrls.length; i++) {
      return () => {
        URL.revokeObjectURL(objectUrls[i]);
      };
    }
  }, [imgFiles]);

  const addProduct = async (e) => {
    e.preventDefault();
    const appendedArray = [...preview, ...uniqueArray];

    img_arr = [];

    formData.append("name", formFields.name);
    formData.append("description", formFields.description);
    formData.append("brand", formFields.brand);
    formData.append("productFor", formFields.productFor);
    formData.append("itemFor", formFields.itemFor);
    formData.append("price", formFields.price);
    formData.append("oldPrice", formFields.oldPrice);
    formData.append("expense", formFields.expense);
    formData.append("category", formFields.category);
    formData.append("subCat", formFields.subCat);
    formData.append("subCatId", formFields.subCatId);
    formData.append("catName", formFields.catName);
    formData.append("subCatName", formFields.subCatName);
    formData.append("countInStock", formFields.countInStock);
    formData.append("rating", formFields.rating);
    formData.append("isFeatured", formFields.isFeatured);
    formData.append("discount", formFields.discount);
    formData.append("productRam", formFields.productRam);
    formData.append("size", formFields.size);
    formData.append("color", formFields.color);
    formData.append("productWeight", formFields.productWeight);
    formFields.detail = details.map((detail) => detail.detail || detail);
    formFields.keyword = keywords.map((keyword) => keyword.keyword || keyword);
    formFields.images = appendedArray;

    if (formFields.name === "") {
      context.setAlertBox({
        open: true,
        msg: "Product name is required",
        color: "error",
      });
      return false;
    }
    if (formFields.description === "") {
      context.setAlertBox({
        open: true,
        msg: "Product description is required",
        color: "error",
      });
      return false;
    }
    if (formFields.brand === "") {
      context.setAlertBox({
        open: true,
        msg: "Product brand is required",
        color: "error",
      });
      return false;
    }
    if (formFields.price === null) {
      context.setAlertBox({
        open: true,
        msg: "Product price is required",
        color: "error",
      });
      return false;
    }
    if (formFields.oldPrice === null) {
      context.setAlertBox({
        open: true,
        msg: "Product oldPrice is required",
        color: "error",
      });
      return false;
    }
    if (formFields.expense === null) {
      context.setAlertBox({
        open: true,
        msg: "Product expense is required",
        color: "error",
      });
      return false;
    }
    if (formFields.countInStock === null) {
      context.setAlertBox({
        open: true,
        msg: "Product stock is required",
        color: "error",
      });
      return false;
    }
    if (formFields.category === "") {
      context.setAlertBox({
        open: true,
        msg: "Product category is required",
        color: "error",
      });
      return false;
    }
    if (formFields.subCat === "") {
      context.setAlertBox({
        open: true,
        msg: "Product sub category is required",
        color: "error",
      });
      return false;
    }
    if (formFields.rating === 0) {
      context.setAlertBox({
        open: true,
        msg: "Product rating is required",
        color: "error",
      });
      return false;
    }
    if (formFields.isFeatured === null) {
      context.setAlertBox({
        open: true,
        msg: "Please select product isFeatured or not",
        color: "error",
      });
      return false;
    }
    if (formFields.keyword === null) {
      context.setAlertBox({
        open: true,
        msg: "Search keywords required",
        color: "error",
      });
      return false;
    }
    if (formFields.itemFor === "") {
      context.setAlertBox({
        open: true,
        msg: "Collection field required",
        color: "error",
      });
      return false;
    }

    if (preview.length === 0) {
      context.setAlertBox({
        open: true,
        msg: "please select images",
        error: true,
      });
      return false;
    }

    setIsLoading(true);
    postData("/api/products/create", formFields).then((res) => {
      context.setAlertBox({
        open: true,
        msg: "The product is created!",
        error: false,
      });

      setIsLoading(false);
      deleteData("/api/imageUpload/deleteAllImages");

      navigate("/products");
    });
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 flex-row p-4">
          <h5>Product Upload</h5>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className="ml-auto"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <form className="form" onSubmit={addProduct}>
          <div className="row">
            <div className="col-md-12">
              <div className="card p-4 mt-0">
                <h5 className="mb-4">Basic Information</h5>
                <div className="form-group">
                  <h6>PRODUCT NAME</h6>
                  <input
                    type="text"
                    name="name"
                    value={formFields.name}
                    onChange={changeInput}
                  />
                </div>
                <div className="form-group">
                  <h6>DESCRIPTION</h6>
                  <textarea
                    rows={5}
                    cols={10}
                    placeholder="Product description"
                    name="description"
                    onChange={changeInput}
                    value={formFields.description}
                  ></textarea>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>CATEGORY</h6>
                      <Select
                        multiple
                        value={category}
                        onChange={handleChangeCategory}
                        // displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {categoryData?.categoryList?.length !== 0 &&
                          categoryData?.categoryList?.map((cat, index) => (
                            <MenuItem
                              value={cat.id}
                              key={index}
                              onClick={() => selectCat(cat.name)}
                            >
                              {cat.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>SUB CATEGORY</h6>
                      <Select
                        multiple
                        value={subCat}
                        onChange={handleChangeSubCategory}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {subCatData?.subCatList?.length !== 0 &&
                          subCatData?.subCatList?.map((subCat, index) => (
                            <MenuItem
                              value={subCat.id}
                              onClick={() => selectSubCat(subCat.subCat)}
                              key={index}
                            >
                              {subCat.subCat}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>Collection</h6>
                      <Select
                        value={itemFor}
                        onChange={handleChangeItemFor}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        <MenuItem value={'winter'}>Winter</MenuItem>
                        <MenuItem value={'summer'}>Summer</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>REGULER PRICE</h6>
                      <input
                        type="number"
                        name="price"
                        onChange={handlePriceChange}
                        value={formFields.price}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>OLD PRICE</h6>
                      <input
                        type="number"
                        name="oldPrice"
                        onChange={handlePriceChange}
                        value={formFields.oldPrice}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>PRODUCT EXPENSE</h6>
                      <input
                        type="number"
                        name="expense"
                        onChange={changeInput}
                        value={formFields.expense}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>IS FEATURED</h6>
                      <Select
                        value={isFeaturedVal}
                        onChange={handleChangeIsFeatured}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        <MenuItem value={null}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>PRODUCT STOCK</h6>
                      <input
                        type="text"
                        name="countInStock"
                        onChange={changeInput}
                        value={formFields.countInStock}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>PRODUCT FOR</h6>
                      <Select
                        value={productFor}
                        onChange={handleChangeProductFor}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        <MenuItem value={'men'}>Men</MenuItem>
                        <MenuItem value={'women'}>Women</MenuItem>
                        <MenuItem value={'kids'}>Kids</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>RATING</h6>
                      <Rating
                        name="simple-controlled"
                        className="ratingControlled"
                        value={ratingValue}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                          setFormFields(() => ({
                            ...formFields,
                            rating: newValue,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>BRAND</h6>
                      <input
                        type="text"
                        name="brand"
                        value={formFields.brand}
                        onChange={changeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Discount</h6>
                      <input type="text" name="discount" value={formFields.discount} readOnly />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>PRODUCT WEIGHT</h6>
                      <Select
                        multiple
                        value={productWeight}
                        onChange={handleChangeProductWeight}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {productWEIGHTData?.map((item, index) => {
                          return (
                            <MenuItem value={item.productWeight}>
                              {item.productWeight}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>PRODUCT RAMS</h6>
                      <Select
                        multiple
                        value={productRams}
                        onChange={handleChangeProductRams}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {productRAMSData?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.productRam}>
                              {item.productRam}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>PRODUCT SIZE</h6>
                      <Select
                        multiple
                        value={productSize}
                        onChange={handleChangeProductSize}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {productSIZEData?.map((item, index) => {
                          return (
                            <MenuItem value={item.size}>{item.size}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <h6>PRODUCT COLOR</h6>
                      <Select
                        multiple
                        value={productColor}
                        onChange={handleChangeProductColor}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100"
                      >
                        {productCOLORData?.map((item, index) => {
                          return (
                            <MenuItem value={item.color}>{item.color}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>Additional details</h6>
                      <input
                        type="text"
                        value={newDetail}
                        onChange={(e) => setNewDetail(e.target.value)}
                        placeholder="Enter additional detail"
                        onKeyDown={handleKeyDown}
                      />
                      <button onClick={handleAddDetail} type="button" className="btn btn-blue">
                        Add Detail
                      </button>
                    </div>
                  </div>
                </div>

                <div className="detailsList">
                  {details.map((detail) => (
                    <div key={detail._id} className="detailsWrapper">
                      <p>
                        {detail.detail}{" "}
                        <span onClick={() => handleDeleteDetail(detail._id)}>
                          <RxCross2 />
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <h6>Search keywords</h6>
                      <input
                        type="text"
                        value={newKeyword}
                        onChange={(e) => setNewKeyword(e.target.value)}
                        placeholder="Enter search keyword"
                        onKeyDown={handleKeywordKeyDown}
                      />
                      <button onClick={handleAddKeywords} type="button" className="btn btn-blue">
                        Add Keyword
                      </button>
                    </div>
                  </div>
                </div>

                <div className="detailsList">
                  {keywords.map((keyword) => (
                    <div key={keyword._id} className="detailsWrapper">
                      <p>
                        {keyword.keyword}{" "}
                        <span onClick={() => handleDeleteKeyword(keyword._id)}>
                          <RxCross2 />
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card p-4 mt-0">
            <div className="imagesUploadSec">
              <h5>Media And Published</h5>

              <div className="imgUploadBox flex2">
                {preview?.length !== 0 &&
                  preview?.map((img, index) => {
                    return (
                      <div className="uploadBox" key={index}>
                        <span
                          className="remove"
                          onClick={() => removeImg(index, img)}
                        >
                          <IoCloseSharp />
                        </span>
                        <div className="box">
                          <LazyLoadImage
                            alt={"image"}
                            effect="blur"
                            className="w-100"
                            src={img}
                          />
                        </div>
                      </div>
                    );
                  })}

                <div className="uploadBox">
                  {uploading === true ? (
                    <div className="progressBar text-center d-flex align-items-center justify-content-center flex-column">
                      <CircularProgress />
                      <span>Uploading...</span>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        multiple
                        onChange={(e) =>
                          onChangeFile(e, "/api/products/upload")
                        }
                        name="images"
                      />
                      <div className="info">
                        <FaRegImages />
                        <h5>image upload</h5>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                className="btn-blue btn-big btn-lg uploadBtn w-100"
              >
                <FaCloudUploadAlt /> &nbsp;
                {isLoading === true ? (
                  <CircularProgress className="loader" color="inherit" />
                ) : (
                  "PUBLISH AND VIEW"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductUpload;
