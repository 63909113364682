import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import { MdClose } from "react-icons/md";
import Button from "@mui/material/Button";
import {  editData, fetchDataFromApi } from "../../utils/api";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

const formatDate = (isoDate) => {
  return moment(isoDate).format("DD/MM/YYYY hh:mm A");
};

//breadcrumb code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [products, setproducts] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { id } = useParams();
  const [singleOrder, setSingleOrder] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const shop = JSON.parse(localStorage.getItem("shop"));
    fetchDataFromApi(`/api/orders?shop=${shop._id}`).then((res) => {
      setOrders(res);
    });
  }, []);
  const showProducts = (id) => {
    fetchDataFromApi(`/api/orders/${id}`).then((res) => {
      setIsOpenModal(true);
      setproducts(res.products);
    });
  };
  const orderStatus = (orderStatus, id) => {
    // if(orderStatus === "pending"){
    fetchDataFromApi(`/api/orders/${id}`).then((res) => {
      const order = {
        name: res.name,
        phoneNumber: res.phoneNumber,
        address: res.address,
        pincode: res.pincode,
        amount: parseInt(res.amount),
        paymentId: res.paymentId,
        email: res.email,
        userid: res.userId,
        products: res.products,
        status: orderStatus,
      };

      editData(`/api/orders/${id}`, order).then((res) => {
        fetchDataFromApi(`/api/orders?page=${1}&perPage=8`).then((res) => {
          setOrders(res);
          window.scrollTo({
            top: 200,
            behavior: "smooth",
          });
        });
      });
      window.location.href = "/orders";
      setSingleOrder(res.products);
    });
    // }
  };
  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4 align-items-center">
          <h5 className="mb-0">Orders List</h5>

          <div className="ml-auto d-flex align-items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="ml-auto breadcrumbs_"
            >
              <StyledBreadcrumb
                component="a"
                href="#"
                label="Dashboard"
                icon={<HomeIcon fontSize="small" />}
              />

              <StyledBreadcrumb
                label="Orders"
                deleteIcon={<ExpandMoreIcon />}
              />
            </Breadcrumbs>
          </div>
        </div>
        <div className="card shadow border-0 p-3 mt-4">
          <div className="table-responsive mt-3">
            <table className="table table-bordered table-striped v-align">
              <thead className="thead-dark">
                <tr>
                  <th>Order Id</th>
                  {/* <th>Products</th> */}
                  <th>Order Details</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {orders?.length !== 0 &&
                  orders
                    .slice(0)
                    ?.reverse()
                    ?.map((order, index) => {
                      const formattedDate = formatDate(order?.date);
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <span className="text-blue fonmt-weight-bold">
                                {order?._id}
                              </span>
                            </td>
                            {/* <td>
                              <span
                                className="text-blue fonmt-weight-bold cursor"
                                onClick={() => showProducts(order?._id)}
                              >
                                Click here to view
                              </span>
                            </td> */}
                            <td>
                              <Link to={`/order/details/${order?.id}`}>
                                See details
                              </Link>
                            </td>
                            <td>{formattedDate}</td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Dialog open={isOpenModal} className="productModal">
        <Button className="close_" onClick={() => setIsOpenModal(false)}>
          <MdClose />
        </Button>
        <h4 class="mb-1 font-weight-bold pr-5 mb-4">Products</h4>

        <div className="table-responsive orderTable">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Product Id</th>
                <th>Product Title</th>
                <th>Image</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>SubTotal</th>
              </tr>
            </thead>

            <tbody>
              {products?.length !== 0 &&
                products?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.productId}</td>
                      <td style={{ whiteSpace: "inherit" }}>
                        <span>{item?.productTitle?.substr(0, 30) + "..."}</span>
                      </td>
                      <td>
                        <div className="img">
                          <img src={item?.image} />
                        </div>
                      </td>
                      <td>{item?.quantity}</td>
                      <td>{item?.price}</td>
                      <td>{item?.subTotal}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
};

export default Orders;
