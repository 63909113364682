import React from 'react'
import { CiSearch } from "react-icons/ci";


const SearchBox = () => {
  return (
    <div className='searchBox position-relative flex2'>
        <CiSearch className='mr-2'/>
        <input type="text" placeholder='Search here...' />
    </div>
  )
}

export default SearchBox