import React, { useContext, useEffect, useState } from "react";
import DashboardBox from "./components/DashboardBox";
import { FaEdit } from "react-icons/fa";
import { IoMdCart, IoMdTrash } from "react-icons/io";
import { LuShoppingBag } from "react-icons/lu";
import { MdOutlineAddChart } from "react-icons/md";
import RestoreIcon from "@mui/icons-material/Restore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, FormControl, Pagination, Rating, TextField } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Chart } from "react-google-charts";
import Select from "@mui/material/Select";
import { IoEye } from "react-icons/io5";
import { MyContext } from "../../App";
import { Link } from "react-router-dom";
import { deleteData, fetchDataFromApi } from "../../utils/api";

export const data = [
  ["Year", "Sales", "Expenses"],
  ["2013", 1000, 400],
  ["2014", 1170, 460],
  ["2015", 660, 1120],
  ["2016", 1030, 540],
];

export const options = {
  backgroundColor: "transparent",
  is3D: true,
  chartArea: { width: "100%", height: "100%" },
};

const ITEM_HEIGHT = 48;
const Dashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBy, setShowBy] = React.useState("");
  const [showCatBy, setShowCatBy] = React.useState("");
  const [brandBy, setBrandBy] = React.useState("");
  const [productList, setProductList] = useState([]);

  const [categoryVal, setcategoryVal] = useState("all");

  const [totalUsers, setTotalUsers] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [totalProductsReviews, setTotalProductsReviews] = useState();
  const [totalSales, setTotalSales] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const context = useContext(MyContext);
  useEffect(() => {
    context.setIsHideSidebarAndHeader(false);
  }, [context.setIsHideSidebarAndHeader]);
  useEffect(() => {
    window.scrollTo(0, 0);
    context.setProgress(40);

    fetchDataFromApi("/api/user/get/count").then((res) => {
      setTotalUsers(res.userCount);
    });
    fetchDataFromApi("/api/products?page=1&perPage=8").then((res) => {
      setProductList(res);
      context.setProgress(100);
    });
    if (context?.isLogin === true) {
      const shop = JSON.parse(localStorage.getItem("shop"));
      fetchDataFromApi(`/api/orders?shop=${shop?._id}`).then((res) => {
        setTotalOrders(res?.length);
      });
      let sales = 0;
      fetchDataFromApi(`/api/orders?shop=${shop?._id}`).then((res) => {
        console.log(res);
        res?.length !== 0 &&
          res?.map((item) => {
            item?.products?.map((pro) => {
              if (pro?.shop === shop?._id) {
                console.log("shop id comparing", pro.subTotal);
                sales += parseInt(pro.subTotal);
              }
              setTotalSales(sales);
            });
          });
      });

      fetchDataFromApi(`/api/productReviews?shop=${shop?._id}`).then((res) => {
        setTotalProductsReviews(res.length);
      });
    }
  }, [context?.isLogin]);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const shop = JSON.parse(localStorage.getItem("shop"));
        fetchDataFromApi(`/api/products?shop=${shop?._id}`).then((res) => {
          setProducts(res);
          setTotalProducts(res?.products?.length);
        });
      } catch (error) {
        console.error("Error fetching data:", error.response.data);
      }
    };

    fetchData();
  }, []);

  const deleteProduct = (id) => {
    context.setProgress(40);
    deleteData(`/api/products/${id}`).then((res) => {
      context.setProgress(100);
      context.setAlertBox({
        open: true,
        error: false,
        msg: "Product Deleted!",
      });
      window.location.reload();
      fetchDataFromApi("/api/products?page=${1}&perPage=8").then((res) => {
        setProductList(res);
      });
    });
  };

  const handleChange = (e, value) => {
    context.setProgress(20);
    fetchDataFromApi(`/api/products?page=${value}&perPage=8`).then((res) => {
      setProductList(res);
      context.setProgress(100);
    });
  };
  const showPerPage = (e) => {
    setShowBy(e.target.value);
    fetchDataFromApi(`/api/products?page=${1}&perPage=${e.target.value}`).then(
      (res) => {
        setProductList(res);
        context.setProgress(100);
      }
    );
  };
  const handleChangeCategory = (event) => {
    if (event.target.value !== "all") {
      setcategoryVal(event.target.value);
      fetchDataFromApi(`/api/products?category=${event.target.value}`).then(
        (res) => {
          setProductList(res);
          context.setProgress(100);
        }
      );
    }
    if (event.target.value === "all") {
      setcategoryVal(event.target.value);
      fetchDataFromApi(`/api/products?page=${1}&perPage=${8}`).then((res) => {
        setProductList(res);
        context.setProgress(100);
      });
    }
  };

  const handleSearch = async (e) => {
    setSearchQuery(e.target.value);
    const shop = JSON.parse(localStorage.getItem("shop"));
    if (e.target.value !== "") {
      const res = await fetchDataFromApi(`/api/searchdashboard?q=${e.target.value}&shop=${shop?._id}`);
      setSearchResults(res);
    } else {
      setSearchResults([]);
    }
  };
  return (
    <>
      <div className="right-content w-100">
        <div className="row dashboardBoxWrapperRow">
          <div className="col-md-8">
            <div className="dashboardBoxWrapper d-flex">
              {/* <DashboardBox
                color={["#1da256", "#48d483"]}
                icon={<FaUserCircle />}
                grow={true}
                title="Total Users"
                count={totalUsers}
              /> */}
              <DashboardBox
                color={["#c012e2", "#eb64fe"]}
                icon={<IoMdCart />}
                title="Total Orders"
                count={totalOrders}
              />
              <DashboardBox
                color={["#2c78e5", "#60aff5"]}
                icon={<LuShoppingBag />}
                title="Total Products"
                count={totalProducts}
              />
              <DashboardBox
                color={["#e1950e", "#f3cd29"]}
                icon={<MdOutlineAddChart />}
                title="Total Reviews"
                count={totalProductsReviews}
              />
            </div>
          </div>
          <div className="col-md-4 pl-0">
            <Button className="box graphBox">
              <div className="d-flex w-100 bottomEle">
                <div className="flex2 w-100">
                  <h6 className="text-white mb-0 mt-0">Total Sales</h6>
                  <div className="ml-auto">
                    <Button className="toggleIcon" onClick={handleClick}>
                      <BsThreeDotsVertical />
                    </Button>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <RestoreIcon /> Last day
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <RestoreIcon /> Last week
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <RestoreIcon /> Last Month
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <RestoreIcon /> Last Year
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
              <h3 className="text-white font-weight-bold">
                {totalSales?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "PKR",
                })}
              </h3>
              <Chart
                chartType="PieChart"
                width="100%"
                height="200px"
                data={data}
                options={options}
              />
            </Button>
          </div>
        </div>
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Best Selling Products</h3>
          <FormControl variant="outlined" className="mr-2">
                  <TextField
                    variant="outlined"
                    placeholder="Search Products"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </FormControl>
          <div className="row cardFilters mt-3">
            <div className="col-md-3">
              <h4>SHOW BY</h4>
              <FormControl size="small" className="w-100">
                <Select
                  value={showBy}
                  onChange={showPerPage}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="demo-select-small-label"
                  className="w-100"
                >
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={70}>70</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-3">
              <h4>CATEGORY BY</h4>
              <FormControl size="small" className="w-100">
                <Select
                  value={categoryVal}
                  onChange={handleChangeCategory}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="w-100"
                >
                  <MenuItem value="all">
                    <em value={"all"}>All</em>
                  </MenuItem>
                  {context.categoryData?.categoryList?.length !== 0 &&
                    context.categoryData?.categoryList?.map((cat, index) => {
                      return (
                        <MenuItem
                          className="text-capitalize"
                          value={cat.id}
                          key={index}
                        >
                          {cat.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="table-responsive mt-3">
            <table className="table table-bordered v-align">
              <thead className="thead-dark">
                <tr>
                  <th>UID</th>
                  <th style={{ width: "300px" }}>PRODUCT</th>
                  <th>CATEGORY</th>
                  <th>BRAND</th>
                  <th>PRICE</th>
                  <th>STOCK</th>
                  <th>RATING</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {searchQuery === "" ?  (
                  products?.products?.map((item, index) => (
                    <tr key={index}>
                      <td>#{index + 1}</td>
                      <td>
                        <div className="flex2 productBox">
                          <div className="imgWrapper">
                            <div className="img">
                              <img
                                src={`${item.images[0]}`}
                                className="w-100"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="info">
                            <h6>{item.name}</h6>
                            <p>{item.description}...</p>
                          </div>
                        </div>
                      </td>
                      <td>{item.category.name}</td>
                      <td>{item.brand}</td>
                      <td>
                        <del className="oldPrice">{item.oldPrice}</del>
                        <span className="newPrice text-danger">
                          {item.price}
                        </span>
                      </td>
                      <td>{item.countInStock}</td>
                      <td>
                        <Rating
                          name="read-only"
                          defaultValue={item.rating}
                          readOnly
                          precision={0.5}
                          size="small"
                        />
                      </td>
                      <td>
                        <div className="actions flex2">
                          <Link to={`/product/details/${item?.staticId}`}>
                            <Button className="secondary" color="secondary">
                              <IoEye />
                            </Button>
                          </Link>
                          <Link to={`/product/edit/${item?.id}`}>
                            <Button className="success" color="success">
                              <FaEdit />
                            </Button>
                          </Link>
                          <Button
                            className="error"
                            color="error"
                            onClick={() => deleteProduct(item.id)}
                          >
                            <IoMdTrash />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  searchResults.map((item, index) => (
                    <tr key={index}>
                      <td>#{index + 1}</td>
                      <td>
                        <div className="flex2 productBox">
                          <div className="imgWrapper">
                            <div className="img">
                              <img
                                src={`${item.images[0]}`}
                                className="w-100"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="info">
                            <h6>{item.name}</h6>
                            <p>{item.description}...</p>
                          </div>
                        </div>
                      </td>
                      <td>{item.category.name}</td>
                      <td>{item.brand}</td>
                      <td>
                        <del className="oldPrice">{item.oldPrice}</del>
                        <span className="newPrice text-danger">
                          {item.price}
                        </span>
                      </td>
                      <td>{item.countInStock}</td>
                      <td>
                        <Rating
                          name="read-only"
                          defaultValue={item.rating}
                          readOnly
                          precision={0.5}
                          size="small"
                        />
                      </td>
                      <td>
                        <div className="actions flex2">
                          <Link to={`/product/details/${item?.staticId}`}>
                            <Button className="secondary" color="secondary">
                              <IoEye />
                            </Button>
                          </Link>
                          <Link to={`/product/edit/${item?.id}`}>
                            <Button className="success" color="success">
                              <FaEdit />
                            </Button>
                          </Link>
                          <Button
                            className="error"
                            color="error"
                            onClick={() => deleteProduct(item.id)}
                          >
                            <IoMdTrash />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))

                )
              
              }
              </tbody>
            </table>
            {productList?.totalPages > 1 && (
              <div className="d-flex tableFooter">
                <Pagination
                  count={productList?.totalPages}
                  color="primary"
                  className="pagination"
                  showFirstButton
                  showLastButton
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
